<template>
  <div :class="{ sidebar: true, open: sidebarOpen }">
    <vue-scroll class="scroller">
      <div class="widget close-filter-box">
        <button @click="sidebarClose()">
          <i class="mdi mdi-close close-icon"></i> Cerrar
        </button>
      </div>
      <div
        class="stiky title-card card-form card-base card-outline card-shadow--small"
      >
        <div>
          <span class="invoice_title"
            ><i class="mdi mdi-file-document"></i
          ></span>
          <el-button
            type="success"
            class="btn"
            size="small"
            @click="saveDocument(false)"
            v-if="!btn_new && !btn_saved"
          >
            <i class="el-icon-printer"></i> Guardar POS
          </el-button>
          <el-button
            v-if="btn_saved"
            type="success"
            class="btn"
            size="small"
            disabled
          >
            <i class="el-icon-loading"></i> Guardando...
          </el-button>
          <el-button
            v-if="btn_new"
            type="default"
            class="btn"
            size="small"
            @click="setBtnNew"
          >
            <i class="mdi mdi-file-plus"></i> Nueva Factura
          </el-button>
          <el-button
            type="primary"
            class="btn"
            size="small"
            @click="openModalSearch"
          >
            <i class="mdi mdi-file-search-outline"></i> Buscar Factura
          </el-button>
        </div>
      </div>

      <div class="widget">
        <div class="title" :class="selected_person ? 'selected-person' : ''">
          <h4 class="m-0 cart-product-details-parent" v-if="selected_person">
            <div class="cart-product-details-child">
              <i class="mdi mdi-account-check"></i>
              {{ personData.name }}
              <br />
              <small>
                <i class="mdi mdi-email"></i>
                {{ personData.email }}
              </small>
              <small>
                <i class="mdi mdi-phone"></i>
                {{ personData.phone }}
              </small>
            </div>
            <a
              href="#"
              class="cart-product-details-child"
              @click="removePerson"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="Buscar otro Cliente"
                placement="top"
              >
                <i class="mdi mdi-close"></i>
              </el-tooltip>
            </a>
          </h4>
          <el-autocomplete
            v-else
            ref="client"
            placeholder="Buscar Cliente..."
            v-model="person_search.nombre"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
            clearable
            size="small"
          >
            <i slot="prefix" class="el-input__icon el-icon-s-custom"></i>
            <template slot-scope="{ item }">
              <div class style="line-height: 1; padding: 8px 0 8px">
                <i class="mdi mdi-account"></i>
                {{ item.nombre }}
                <br />
                <small>
                  <i class="mdi mdi-clipboard-account"></i>
                  {{ item.documento }}
                </small>
              </div>
            </template>
            <template slot="append">
              <el-tooltip
                class="item"
                effect="dark"
                content="Registrar Nuevo"
                placement="top"
              >
                <el-popover
                  placement="bottom-start"
                  ref="registerClient"
                  trigger="click"
                  @after-enter="focusField"
                >
                  <div class="content-data">
                    <div
                      class="page-header header-accent-gradient card-base card-shadow--small header-client"
                    >
                      <h1>
                        <i class="mdi mdi-account-multiple-plus"></i> Registro
                        de Cliente
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Guardar Cliente"
                          placement="top"
                        >
                          <el-button
                            icon="mdi mdi-content-save-outline"
                            circle
                            class="btn-person-save"
                            @click="savePerson('formPerson')"
                          ></el-button>
                        </el-tooltip>
                      </h1>
                    </div>
                    <div class="form-box">
                      <el-form
                        ref="formPerson"
                        label-width="100px"
                        size="medium"
                        :model="personData"
                        :rules="rules"
                        v-loading="loading"
                      >
                        <el-form-item label="Cédula / Nit" prop="nit">
                          <el-input
                            ref="nit"
                            placeholder="Cédula / Nit"
                            v-model="personData.nit"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Nombre" prop="name">
                          <el-input
                            placeholder="Nombre"
                            v-model="personData.name"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Teléfono" prop="phone">
                          <el-input
                            placeholder="Teléfono"
                            v-model="personData.phone"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="E-mail" prop="email">
                          <el-input
                            placeholder="E-mail"
                            v-model="personData.email"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Dirección" prop="address">
                          <el-input
                            placeholder="Dirección"
                            v-model="personData.address"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Ciudad" prop="city">
                          <el-input
                            placeholder="Ciudad"
                            v-model="personData.city"
                          ></el-input>
                        </el-form-item>
                        <el-form-item label="Cumpleaños">
                          <el-date-picker
                            v-model="personData.birthday"
                            type="date"
                            placeholder="aaaa-mm-dd"
                            value-format="yyyy-MM-dd"
                          ></el-date-picker>
                        </el-form-item>
                      </el-form>
                    </div>
                  </div>
                  <el-button
                    slot="reference"
                    icon="mdi mdi-account-edit"
                    style="font-size: 25px"
                  >
                    <small style="font-size: 13px">(F2)</small>
                  </el-button>
                </el-popover>
              </el-tooltip>
            </template>
          </el-autocomplete>
          <!-- <a href="#" style="font-size:12px;margin-top:10px;"><i class="mdi mdi-account-edit"></i> Elegir cliente por defecto</a> -->
        </div>
        <div class="content">
          <div class="content-data">
            <div
              class="page-header header-accent-gradient card-base card-shadow--small"
            >
              <h1><i class="mdi mdi-cash-multiple"></i> Forma de pago</h1>
            </div>
            <div class="form-box">
              <el-form ref="formPayment" label-width="150px" size="medium">
                <el-select
                  ref="payment_method"
                  v-model="payment_selected"
                  clearable
                  filterable
                  placeholder="Selecciona la forma de pago"
                  class="payment-select"
                  value-key="id"
                  @change="setPaymentMethod"
                >
                  <el-option
                    v-for="item in payments"
                    :key="item.id"
                    :label="item.descripcion"
                    :disabled="item.disabled"
                    :value="item"
                  ></el-option>
                </el-select>
                <el-form-item
                  v-for="(pay, index) in payment_method"
                  v-bind:key="pay.id"
                  :label="pay.descripcion"
                >
                  <el-input
                    :ref="pay.descripcion"
                    :disabled="pay.disabled_value"
                    placeholder="$"
                    v-model="pay.value"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-delete"
                      @click="deletePayment(pay, index)"
                    ></el-button>
                  </el-input>
                  <el-input
                    v-if="pay.description_text"
                    :ref="pay.description"
                    :placeholder="pay.description_text"
                    v-model="pay.description"
                    size="small"
                    style="margin-top: 2px; width: 80%"
                  ></el-input>
                  <el-input
                    v-if="pay.description1_text"
                    :ref="pay.description1"
                    :placeholder="pay.description1_text"
                    v-model="pay.description1"
                    size="small"
                    style="width: 80%"
                  ></el-input>
                  <el-input
                    v-if="pay.description2_text"
                    :ref="pay.description2"
                    :placeholder="pay.description2_text"
                    v-model="pay.description2"
                    size="small"
                    style="width: 80%"
                  ></el-input>
                  <el-input
                    v-if="pay.credit_note_text"
                    :ref="pay.credit_note"
                    :placeholder="pay.credit_note_text"
                    v-model="pay.credit_note"
                    size="small"
                    style="margin-top: 2px; width: 80%"
                  >
                    <el-button
                      slot="append"
                      :icon="
                        loading_credit_note
                          ? 'el-icon-loading'
                          : 'el-icon-search'
                      "
                      @click="searchDocument(index, pay.id, pay.credit_note)"
                    ></el-button>
                  </el-input>
                  <el-input
                    v-if="pay.warranty_text"
                    :ref="pay.warranty"
                    :placeholder="pay.warranty_text"
                    v-model="pay.warranty"
                    size="small"
                    style="margin-top: 2px; width: 80%"
                  >
                    <el-button
                      slot="append"
                      icon="el-icon-search"
                      @click="searchDocument(index, pay.id, pay.warranty)"
                    ></el-button>
                  </el-input>
                </el-form-item>
                <el-card shadow="always">
                  <el-form-item label="Recibido">
                    <div class="border-bottom totals-values total-payment">
                      <i class="mdi mdi-currency-usd"></i>
                      <span class="total-data">{{
                        this.formatNumber(total_pay)
                      }}</span>
                    </div>
                  </el-form-item>
                  <el-form-item label="Devolución">
                    <div
                      class="border-bottom totals-values total-payment"
                      :class="return_pay < 0 ? 'text-danger' : ''"
                    >
                      <i class="mdi mdi-currency-usd"></i>
                      <span class="total-data">{{
                        this.formatNumber(return_pay)
                      }}</span>
                    </div>
                  </el-form-item>
                </el-card>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
    <modal-search
      :modalSearchOpen="modalSearchOpen"
      :printerDefault="printerDefault"
      @close="modalSearchOpen = false"
    ></modal-search>
  </div>
</template>

<script>
import ModalSearch from "../../../views/apps/pos/ModalSearch.vue";
import { setPause, getPause, removePause } from "@/utils/pauseDocument";
import { getUser } from "@/utils/auth";
import { getPayments } from "@/api/payment";
import { getDocumentForPay } from "@/api/pos";
import { search as searchClient, save as savePerson } from "@/api/person";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
import { mapGetters } from "vuex";
export default {
  name: "PersonPay",
  props: ["sidebarOpen", "value_invoice", "resetFields", "printerDefault"],
  data() {
    return {
      loading: false,
      loading_credit_note: false,
      loading_waranty: false,
      modalSearchOpen: false,
      user: getUser(),
      userdata: {
        id: null,
        date: null,
      },
      disabled_value_pay: false,
      payments: [],
      payment_selected: "",
      payment_method: [],
      total_pay: 0,
      selected_person: false,
      person_search: {},
      personData: {
        id: "",
        nit: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        birthday: "",
      },
      rules: {
        nit: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        name: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        // email: [
        //   { required: true, message: "Campo Obligatorio", trigger: "blur" },
        //   {
        //     type: "email",
        //     message: "Ingrese un Email valido",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        phone: [
          { required: true, message: "Campo Obligatorio", trigger: "blur" },
        ],
        // city: [
        //   { required: true, message: "Campo Obligatorio", trigger: "blur" },
        // ],
      },
    };
  },
  computed: {
    ...mapGetters([
      //for view
      "document_view",
      "btn_new",
      "btn_saved",
    ]),
    return_pay: function () {
      return this.total_pay - this.value_invoice;
    },
  },
  watch: {
    // VIEW
    document_view: {
      deep: true,
      handler(val, oldVal) {
        this.setViewDocument(val);
      },
    },
    resetFields: function (val) {
      if (val) {
        this.resetDocument();
      }
    },
    payment_method: {
      deep: true,
      handler(val, oldVal) {
        this.total_pay = 0;
        if (val.length > 0) {
          val.forEach(
            (el) =>
              (this.total_pay += el.value != "" ? parseFloat(el.value) : 0)
          );
        }
        this.$store.commit("SET_PAYMENT", val);
      },
    },
    person_search: {
      deep: true,
      handler(val, oldVal) {
        if (val != "") {
          this.personData.nit = val.nombre;
        } else {
          this.personData.nit = "";
        }
      },
    },
  },
  methods: {
    setBtnNew() {
      this.$store.commit("SET_BTN_NEW", false);
    },
    setViewDocument(data) {
      if (data !== "") {
        this.handleSelect(data.person);
        this.payment_method = [];
        data.payment_detail.forEach((el) => {
          const idx = this.payments.findIndex(
            (element) => element.id === el.payment.id
          );
          this.payments[idx].value = el.valor;
          if (el.garantia) {
            this.payments[idx].warranty = el.garantia;
          }
          if (el.nota_credito) {
            this.payments[idx].credit_note = el.nota_credito;
          }
          this.payment_method.push(this.payments[idx]);
        });
      }
    },
    handleCommand(command) {
      if(command == 'true'){
        this.saveDocument(true);
      }
      if (command == 'pause') {
        setPause({
          document: {
            id:1212,
            consecutive:3333
          },
          detail: [
            {id: 1, description:'huevos'},
            {id: 2, description:'leche'},
            {id: 3, description:'pan'},
          ],
          payments: [
            {id:1, description: 'Efectivo'}
          ]
        })
        console.log(command);
      }
    },
    openModalSearch() {
      this.modalSearchOpen = true;
    },
    onKeyDownHandler() {
      document.addEventListener("keydown", function (e) {
        console.log("code", e.keyCode);
      });
    },
    registerClient() {
      console.log("f1 registrar cliente");
    },
    searchDocument(index, payment_id, consecutive) {
      if (payment_id == 5) {
        this.loading_credit_note = true;
      } else {
        this.loading_waranty = true;
      }
      getDocumentForPay(payment_id, consecutive)
        .then(({ data }) => {
          if (payment_id == 5) {
            this.loading_credit_note = false;
          } else {
            this.loading_waranty = false;
          }
          if (data.code === 200) {
            this.payment_method[index].value = roundToTheNearestAnything(
              data.data
            );
          } else {
            this.$confirm(data.data, "Atencion!", {
              showCancelButton: false,
              confirmButtonText: "OK",
              type: "warning",
              center: true,
            });
          }
        })
        .catch((error) => {
          if (payment_id == 5) {
            this.loading_credit_note = false;
          } else {
            this.loading_waranty = false;
          }
          console.log(error);
        });
    },
    deletePayment(pay, index) {
      var payment = this.payments.filter((data) => data.id === pay.id);
      payment[0].disabled = false;
      this.payment_method[index].value = "";
      this.payment_method.splice(index, 1);
    },
    setPaymentMethod(selected) {
      let me = this;
      if (selected !== "") {
        if (!this.payment_method.find((el) => el.id === selected.id)) {
          selected.disabled = true;
          this.payment_method.push(selected);
          if (selected.id === 5 || selected.id === 10) {
            me.disabled_value_pay = true;
          }
        } else {
          this.$message({
            message:
              "Atención!, La forma '" +
              selected.descripcion +
              "' de pago ya esta disponible.",
          });
        }
        setTimeout(() => {
          me.$nextTick(() => me.$refs[selected.descripcion][0].focus());
          me.payment_selected = "";
        }, 400);
      }
    },
    saveDocument(electronic) {
      let me = this;
      this.validateDocument().then((resp) => {
        if (resp) {
          this.$store.commit("SET_BTN_SAVED", true);
          setTimeout(() => {
            if (me.total_pay < me.value_invoice) {
              me.$message({
                message:
                  "Atención!, El valor recibido es menor al valor a pagar.",
                type: "warning",
              });
              this.$store.commit("SET_BTN_SAVED", false);
            } else {
              this.$emit("save", electronic);
            }
          }, 1000);
        }
      });
    },
    async validateDocument() {
      let me = this;
      var save = true;
      var pay = true;
      if (this.personData.id == "") {
        save = false;
        this.$message({
          message: "Atención!, Debes seleccionar un cliente.",
          type: "warning",
        });
        this.$nextTick(() => this.$refs.client.focus());
      } else {
        if (this.payment_method.length === 0) {
          save = false;
          this.$message({
            message: "Atención!, Debes ingresar una forma de pago.",
            type: "warning",
          });
          this.$nextTick(() => this.$refs.payment_method.focus());
        } else {
          if (this.payment_method.length > 0) {
            await this.payment_method.forEach((el) => {
              if (el.value !== "") {
                pay = false;
              }
              setTimeout(() => {
                if (el.warranty === "" || el.credit_note === "") {
                  save = false;
                  me.$message({
                    message:
                      "Atención!, Debes ingresar y buscar el numero de '" +
                      el.descripcion +
                      "' para continuar",
                    type: "warning",
                  });
                  el.id === 10
                    ? me.$nextTick(() => me.$refs[el.warranty][0].focus())
                    : me.$nextTick(() => me.$refs[el.credit_note][0].focus());
                }
              }, 100);
            });

            if (pay) {
              save = false;
              this.$message({
                message:
                  "Atención!, Debes ingresar el valor en la forma de pago",
                type: "warning",
              });
            }
          }
        }
      }
      return save;
    },
    sidebarClose() {
      this.$emit("close");
    },
    savePerson(form) {
      this.loading = true;
      var data = {
        documento: this.personData.nit,
        nombre: this.personData.name,
        email: this.personData.email,
        telefono: this.personData.phone,
        direccion: this.personData.address,
        ciudad: this.personData.city,
        nacimiento: this.personData.birthday,
        cliente: 1,
      };
      this.$refs[form].validate((valid) => {
        if (valid) {
          savePerson(data)
            .then(({ data }) => {
              this.loading = false;
              if (data.code === 200) {
                this.setClientStore(data.data);
                this.personData.id = data.data.id;
                this.$message({
                  type: "success",
                  message: "Registro de clinete exitoso!",
                });
                this.selected_person = true;
              } else {
                if (data.error.errorInfo[1] === 1062) {
                  this.$message.error(
                    "Error: El número de cedula o NIT ya existe. "
                  );
                  this.person_search.nombre = this.personData.nit;
                  this.$nextTick(() => this.$refs.client.focus());
                } else {
                  if (data.error.errorInfo[1] === 1048) {
                    this.$message.error(
                      "Error: Uno de los campos no puede ir vacio: " +
                        data.error.errorInfo[2]
                    );
                  } else {
                    this.$message.error(
                      "Oops, Error al registrar. " + data.error.errorInfo[2]
                    );
                  }
                }
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$message.error("Oops, Error al registrar. ");
              console.log(error);
            });
        } else {
          this.loading = false;
          this.$message({
            message: "Atención!, Completa los campos obligatorios.",
            type: "warning",
          });
          return false;
        }
      });
    },
    removePerson() {
      this.selected_person = false;
      this.personData = {
        id: "",
        nit: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        birthday: "",
      };
    },
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.length > 3) {
        searchClient(queryString, "cliente")
          .then(({ data }) => {
            cb(data.data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleSelect(item) {
      this.personData = {
        id: item.id,
        nit: item.documento,
        name: item.nombre,
        email: item.email,
        phone: item.telefono,
        address: item.direccion,
        city: item.ciudad,
        birthday: item.nacimiento,
      };
      this.setClientStore(this.personData);
      this.selected_person = true;
    },
    setClientStore(data) {
      this.$store.commit("SET_CLIENT", data);
    },
    formatNumber(number) {
      //PASAR INTEGER A FORMATO NUMERO
      return formatNumber(number);
    },
    getPaymentsData() {
      getPayments(this.user.sucursal_id)
        .then(({ data }) => {
          data.forEach((el) => {
            el.value = "";
            el.disabled = false;
            el.disabled_value = false;
            if (el.id === 2) {
              // NEQUI
              el.description = "";
              el.description1 = "";
              el.description2 = "";
              el.description_text = "N° Cuenta";
              el.description1_text = "Banco";
              el.description2_text = "Oficina";
            }
            if (el.id === 3) {
              // TC/TD
              el.description = "";
              el.description1 = "";
              el.description1_text = "Codigo autorización (0000)";
            }
            if (el.id === 5) {
              // NOTA CREDITO
              el.credit_note = "";
              el.credit_note_text = "N° Nota credito";
              el.value = 0;
              el.disabled_value = true;
            }
            if (el.id === 10) {
              // GARANTIA
              el.warranty = "";
              el.warranty_text = "N° Garantia";
              el.value = 0;
              el.disabled_value = true;
            }
            // ASIGNAR FORMAS DE PAGO POR DEFECTO
            // if (el.id === 1 || el.id === 3 || el.id === 4) {
            if (el.id === 1) {
              el.disabled = true;
              this.payment_method.push(el);
            }
          });
          this.payments = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetDocument() {
      this.getPaymentsData();
      this.userdata = {
        id: null,
        date: null,
      };
      this.disabled_value_pay = false;
      this.payment_selected = "";
      this.payment_method = [];
      this.total_pay = 0;
      this.selected_person = false;
      this.person_search = {};
      this.personData = {
        id: "",
        nit: "",
        name: "",
        email: "",
        phone: "",
        address: "",
        city: "",
        birthday: "",
      };
    },
    focusField() {
      this.$nextTick(() => this.$refs.nit.focus());
    },
  },
  created() {
    this.getPaymentsData();
    document.addEventListener("keydown", (e) => {
      if (e.key == "F2") {
        if (this.$refs.registerClient) {
          const elem = this.$refs.registerClient.$children[2].$el.click();
          this.$nextTick(() => this.$refs.nit.focus());
        }
      }
    });
  },
  components: {
    ModalSearch,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";
@import "../../../assets/scss/app/personPay";

.payment-select {
  margin-bottom: 20px;
}
</style>