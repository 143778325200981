<template>
  <el-dialog :title="'Pago # ' + data.consecutive" width="50%" :visible.sync="openModalView"
    :close-on-click-modal="false" :close-on-press-escape="false" :before-close="closeModalView" :destroy-on-close="true"
    top="5vh">
    <el-row :gutter="10">
      <el-col :xs="6" :sm="6" :md="8" :lg="8" :xl="8">
        <h3>Fecha:</h3>
        <i class="el-icon-date"></i> {{ data.date }}
      </el-col>
      <el-col :xs="6" :sm="6" :md="8" :lg="8" :xl="8">
        <h3>Consecutivo de pago:</h3>
        #{{ data.consecutive }}
      </el-col>
      <el-col :xs="6" :sm="6" :md="8" :lg="8" :xl="8">
        <h3>Proveedor:</h3>
        <i class="el-icon-user"></i> {{ (data) ? data.person.nombre : '' }}<br>
        <i class="el-icon-postcard"></i> {{ (data) ? data.person.documento : '' }}
      </el-col>
    </el-row>
    <h3>Detalle del pago</h3>
    <el-table :data="data.detail" style="width: 100%; margin-top: 20px">
      <el-table-column label="Fecha" prop="date" sortable></el-table-column>
      <el-table-column label="Factura" prop="invoice" sortable></el-table-column>
      <el-table-column label="Descripción" prop="description" sortable></el-table-column>
      <el-table-column label="Tipo de pago" prop="provider_payment_types" sortable>
        <template slot-scope="scope">
          <span>{{ scope.row.provider_payment_types.description }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Valor" prop="value_paid" sortable>
        <template slot-scope="scope">
          <span>${{ formatNumber(scope.row.value_paid) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="closeModalView" icon="el-icon-close">Cerrar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatInt, formatNumber } from "@/utils/number";
export default {
  name: 'ModalViewPayment',
  props: ['openModalView', 'dataModalView'],
  data() {
    return {
      data: {
        id: '',
        consecutive: '',
        date: '',
        detail: [],
        person: null
      },
      loading: false,
      // Pagination
      pageSize: 100,
      currentPage: 1,
      total_rows: 0,
    }
  },
  watch: {
    dataModalView: {
      deep: true,
      handler(val, oldVal) {
        if (Object.keys(val).length !== 0) {
          this.openModalForm();
        } else {
          this.resetData()
        }
      },
    },
  },
  methods: {
    openModalForm() {
      this.data = (this.dataModalView) ? this.dataModalView : '';
    },
    resetData() {
      this.data = {
        id: '',
        consecutive: '',
        date: '',
        detail: [],
        person: null
      };
    },
    closeModalView(refreshTable) {
      this.loading = false;
      this.$emit('close', null);
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(formatInt(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
  },
  created() {
    this.openModalForm()
  },
}
</script>

<style lang="scss" scope>
.content-box {
  display: grid;
  place-items: center;
}

.icon-box {
  font-size: 50px;
}

.el-autocomplete {
  width: 100%;
}
</style>