<template>
  <!-- MODAL TWO  -->
  <el-dialog width="50%" :title="'Detalle documento ' + consecutive" :visible.sync="openModalTwo"
    :before-close="closeModal" :destroy-on-close="true" :close-on-click-modal="false" append-to-body>
    <el-table :data="tableData" style="width: 100%" height="280" size="medium" v-loading="loading"
      element-loading-text="Cargando Datos..." element-loading-spinner="el-icon-loading"
      element-loading-background="rgb(246 246 246 / 80%)">
      <el-table-column prop="product.codigo_interno" label="Codigo" width="100">
      </el-table-column>
      <el-table-column prop="product.descripcion" label="Producto"></el-table-column>
      <el-table-column prop="product.category.descripcion" label="Categoria"></el-table-column>
      <el-table-column prop="cant_final" label="Cantidad" width="100"></el-table-column>
      <el-table-column align="right" label="Acciones" width="80">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)" circle>
              <i class="el-icon-delete"></i>
            </el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <div v-if="tableData.length > 0" class="pagination-box" style="text-align: end; padding-top: 10px">
      <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
        @current-change="handleCurrentPage"></el-pagination>
    </div>
  </el-dialog>
</template>
<script>
import { getDetailByDocumentId, deleteDetailById } from "@/api/document";
export default {
  name: "ModalListDocumentDetail",
  props: ["openModalTwo", "documentId", "consecutive", "transaction"],
  watch: {
    documentId(val) {
      if (val) {
        this.getDetailDocument();
      }
    },
  },
  data() {
    return {
      loading: false,
      openModal: false,
      tableData: [],
      // Pagination
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    getDetailDocument() {
      this.loading = true
      getDetailByDocumentId(this.pageSize,
        this.currentPage,
        this.documentId,
        this.transaction)
        .then(({ data }) => {
          this.loading = false;
          this.total_rows = data.data.total;
          this.tableData = data.data.data;
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.$message.error("Oops", "Error.");
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getDetailDocument();
    },
    handleDelete(row) {
      let me =this
      this.$confirm('Deseas eliminar este detalle del documento ' + this.consecutive + '?', 'Atención', {
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'Cancelar',
        confirmButtonClass: 'el-button--danger',
        type: 'warning'
      }).then(() => {
        me.deleteDetailDocument(row.id)
      })
    },
    deleteDetailDocument(id) {
      this.loading = true
      deleteDetailById(id)
        .then(({ data }) => {
          this.getDetailDocument()
          this.$message({
            type: 'success',
            message: 'Detalle eliminado correctamente'
          });
        })
        .catch(function (error) {
          console.log(error);
          this.loading = false;
          this.$message.error("Oops", "Error.");
        });
    },
    closeModal() {
      this.tableData = []
      this.pageSize = 10
      this.currentPage = 1
      this.total_rows = 0
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss"></style>