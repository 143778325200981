<template>
  <div class="page-gallery scrollable only-y">
    <div
      class="page-header header-accent-gradient card-base card-shadow--small"
    >
      <h1>
        <span>Devoluciones</span>
        <el-button
          type="primary"
          icon="el-icon-s-grid"
          @click="modalListOpen = true"
          >Listar Devoluciones</el-button
        >
      </h1>
      <div class="flex justify-space-between">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>
            <i class="el-icon-notebook-2"></i>
            {{
              type_document != "" && type_document != null
                ? type_document.descripcion
                : ""
            }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-card class="box-card">
      <el-steps :active="active" align-center :finish-status="finishStatus">
        <el-step title="Tipo de Documento" icon="el-icon-document"></el-step>
        <el-step title="# factura o NIT" icon="el-icon-postcard"></el-step>
        <el-step
          title="Verificar Datos"
          icon="el-icon-document-checked"
        ></el-step>
      </el-steps>
      <!-- COMPONENT RENDER -->
      <component class="content-form" :is="renderComponent" :data="data" />
      <div class="btn-steps">
        <el-button
          size="small"
          icon="el-icon-d-arrow-left"
          @click="previous"
          v-if="active > 0 && !print_btn"
          >Anterior</el-button
        >
        <el-button size="small" type="primary" @click="next" v-if="active < 2"
          >Siguiente <i class="el-icon-d-arrow-right"></i
        ></el-button>
        <el-button
          size="small"
          type="success"
          @click="save"
          v-if="active > 1 && !print_btn"
          >Guardar e Imprimir <i class="el-icon-printer"></i
        ></el-button>
        <el-button size="small" type="success" @click="print" v-if="print_btn"
          >Imprimir <i class="el-icon-printer"></i
        ></el-button>
        <el-button
          size="small"
          type="primary"
          @click="newRegister"
          v-if="print_btn"
          >Crear Nuevo <i class="el-icon-plus"></i
        ></el-button>
      </div>
    </el-card>
    <modal-list
      :modalListOpen="modalListOpen"
      @close="modalListOpen = false"
      @print="getDocumentById"
    ></modal-list>
  </div>
</template>

<script>
// 2975
const axios = require('axios');
import * as qz from "qz-tray";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
} from "@/utils/number";
import { mapGetters } from "vuex";
import { getUser, getDemo, getDataPos } from "@/utils/auth";
import { saveRefund } from "@/api/document";
import { getDocumentById } from "@/api/pos";
import Step1 from "@/views/apps/refund/Step1.vue";
import Step2 from "@/views/apps/refund/Step2.vue";
import Step3 from "@/views/apps/refund/Step3.vue";
import ModalList from "@/views/apps/refund/ModalList.vue";
export default {
  name: "refund",
  components: {
    Step1,
    Step2,
    Step3,
    ModalList,
  },
  computed: {
    ...mapGetters([
      "return_money",
      "refund_type",
      "refund_data",
      "printer_default",
      "printerReConnect",
    ]),
  },
  watch: {
    refund_type: {
      deep: true,
      handler(val, oldVal) {
        this.type_document = val;
        this.data_document = null;
      },
    },
    refund_data: {
      deep: true,
      handler(val, oldVal) {
        if (val) {
          this.data_document = val;
        }
      },
    },
  },
  data() {
    return {
      demo: getDemo(),
      active: 0,
      modalListOpen: false,
      print_btn: false,
      finishStatus: "success",
      renderComponent: "step1",
      data: "",
      dataPos: getDataPos(),
      user: getUser(),
      type_document: null,
      data_document: null,
      // data print
      dataPrint: "",
      social_networks: "Siguenos en nuestras redes sociales: xplod.nyc",
      contact_print:
        "Contactanos Oficina principal 032 695 4503 Celular 316 7433278",
      id: "",
      company: {},
      document: {},
      detail: {},
      payments: [],
      detailTableText: "",
      paymentTableText: "",
      detailData: [
        {
          description: "",
          quantity: 0,
          price: 0,
        },
      ],
      data: {
        id: "",
        consecutive: "",
        branch: "",
        branch_nit: "",
        branch_address: "",
        client: "",
        client_name: "",
        client_address: "",
        client_nit: "",
        seller: "",
      },
      print_payment: 0,
      print_subtotal: 0,
      print_total: 0,
      print_discount: 0,
      print_iva: 0,
      print_ipc: 0,
    };
  },
  methods: {
    print() {
      let me = this;
      var data = this.dataPrint;
      this.company = data.company;
      this.document = data.document;
      this.detail = data.detail;
      this.assignData(data.document);
      this.payments = data.document.payment_detail;
      this.print_payment = 0;
      this.paymentTableText = "";

      if (data.document.payment_detail.length > 0) {
        data.document.payment_detail.forEach(function (el) {
          me.paymentTableText +=
            '<tr><td style="padding-left:10px;">' +
            el.payment.descripcion +
            '</td><td style="text-align:right;padding-right:10px">' +
            "$ " +
            formatNumber(formatInt(el.valor)) +
            "</td></tr>";
          me.print_payment += el.valor;
        });
      }

      this.detailData = [];
      if (data.detail.length > 0) {
        this.assignDataDetail(data.detail);
      }
      this.startPrint();
    },
    assignData(data) {
      this.data = {
        id: data.id,
        consecutive: data.consecutivo,
        type: data.type,
        branch: data.branch.razon_social,
        branch_nit: data.branch.nit,
        branch_address: data.branch.direccion,
        client: data.person.nombre,
        client_document: data.person.documento,
        client_address: data.person.direccion,
        client_phone: data.person.telefono,
        seller: data.seller.nombre,
        discount: data.descuento_valor,
      };
    },
    assignDataDetail(data) {
      let me = this;
      me.print_subtotal = 0;
      me.print_total = 0;
      me.print_discount = 0;
      me.print_iva = 0;
      me.print_ipc = 0;
      me.detailTableText = "";
      data.forEach(function (el, ind) {
        me.detailTableText +=
          '<tr><td style="padding-left:10px;">' +
          el.product.descripcion +
          "<br>" +
          el.product.codigo +
          '</td><td style="">' +
          el.product.category.descripcion +
          '</td><td style="text-align:center;">' +
          el.cantidad +
          '</td><td style="text-align:right;padding-right:10px">' +
          "$ " +
          formatNumber(formatInt(el.precio)) +
          "</td></tr>";
        me.print_subtotal += formatInt(el.precio) * formatInt(el.cantidad);
        me.print_discount += formatInt(el.descuento);
        me.print_iva = el.iva;
        me.print_ipc += el.ipc * el.cantidad;
        me.detailData.push({
          description: el.product.descripcion,
          quantity: el.cantidad,
          price: "$ " + formatNumber(formatInt(el.precio)),
        });
      });
      this.print_discount =
        this.print_discount + formatInt(this.document.descuento_valor);
      this.print_iva =
        ((this.print_subtotal - this.print_discount) * me.print_iva) / 100;
      this.print_total = roundToTheNearestAnything(
        this.print_subtotal -
          this.print_discount +
          this.print_iva +
          me.print_ipc
      );
    },
    startPrint() {
      let me = this;
      if (me.printer_default !== "") {
        if (!qz.websocket.isActive()) {
          me.startConnectionPrint();
        }
        setTimeout(() => {
          me.sendPrinter(me.printer_default);
        }, 300);
      }
    },
    sendPrinter(printer) {
      let me = this;
      var config = qz.configs.create(printer, {  scaleContent : false  }); // Create a default config for the found printer
      var metadata = false;
      if (this.data.type.meta_data !== null) {
        metadata = JSON.parse(this.data.type.meta_data);
      }
      var documentHead = '<div style="text-align:center;">' +
            this.company.razon_social +
            "</div>" +
            '<div style="text-align:center;">NIT: ' +
            this.company.nit +
            "</div>" +
            '<div style="margin: 10px 0 10px 0;"></div>' +
            '<div style="text-align:center;">' +
            this.data.branch +
            "</div>" +
            '<div style="text-align:center;">' +
            this.data.branch_address +
            "</div>" +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // invoice
            '<div style="text-align:center;">' +
            this.data.type.descripcion +
            " #" +
            this.data.type.prefijo +
            "" +
            this.data.consecutive.toString().padStart(6, "0") +
            "</div>" +
            '<div style="text-align:center;">RESPONSABLES DE IVA</div>' +
            '<div style="text-align:center;">' +
            this.document.fecha +
            "</div>";
      var documentFooter = "Contactanos Oficina principal 032 379 0546 Celular 316 7433278" +
            "</div>" +
            '<div style="font-size:12px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
            "Siguenos en nuestras redes sociales: xplod.nyc" +
            "</div>" +
            '<div style="font-size:10px;text-align:center;margin-bottom:10px;">https://www.xplodnyc.com</div>' +
            '<div style="text-align:center;margin-top:2px;margin-bottom:2px;font-size:9px">Desarrollado por aplicacionesexcel.com</div>';
      var background = '';
      if(this.demo){
        background = 'background-image: url(\''+process.env.VUE_APP_ROOT+'static/images/demo.png\');';
        documentHead =
          '<div style="font-size:30px;text-align:center;">DEMO</div>';
        documentFooter =
          '<div style="font-size:30px;text-align:center;">DEMO</div>';
      }
      // console.log("sendPrint", this.data.consecutive, this.company, this.data);
      var printData = [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data:
            "<html>" +
            '<body style="font-size:11px;'+background+'">' +
            documentHead +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // client
            '<div style="padding-left:10px;">Cliente: ' +
            this.data.client +
            "</div>" +
            '<div style="padding-left:10px;">Cedula O NIT: ' +
            this.data.client_document +
            "</div>" +
            '<div style="padding-left:10px;">Teléfono: ' +
            this.data.client_phone +
            "</div>" +
            '<div style="padding-left:10px;">Vendedor: ' +
            this.data.seller +
            "</div>" +
            // table detail
            '<table style="width:100%;font-size:11px;">' +
            '<tr><td style="border-top: 1px dashed;border-bottom: 1px dashed;">Descripción</td>' +
            '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Categoria</td>' +
            '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Cant.</td>' +
            '<td style="text-align:right;border-top: 1px dashed;border-bottom: 1px dashed;padding-right:10px">Precio</td></tr>' +
            this.detailTableText +
            "</table>" +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            // totals
            '<div style="padding-left:10px;">Subtotal <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_subtotal) +
            "</label></div>" +
            '<div style="padding-left:10px;">Descuento <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_discount) +
            "</label></div>" +
            '<div style="padding-left:10px;">IVA 19% <label style="float:right;padding-right:10px">$ ' +
            formatNumber(Math.trunc(this.print_iva)) +
            "</label></div>" +
            '<div style="padding-left:10px;">Impo. al Consumo Bolsa <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_ipc) +
            "</label></div>" +
            '<div style="padding-left:10px;">Total <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_total) +
            "</label></div>" +
            '<div style="border-bottom: solid 1px;margin: 10px 0 10px 0;"></div>' +
            //payments
            '<table style="width:100%;font-size:11px;">' +
            this.paymentTableText +
            "</table>" +
            '<div style="padding-left:10px;">OBSERVACIÓN: ' +
            this.document.observacion +
            "</div>" +
            '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
            documentFooter +
            '<div style="margin: 20px 0 20px 0;border-style: dashed; border-width: 1px;"></div>' +
            "</div>" +
            "</body>" +
            "</html>",
        },
      ];
      qz.print(config, printData)
        .then(function () {
          me.$store.commit("SET_ID_DOCUMENT", "");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    // FINISH PRINT
    getDocumentById(id) {
      let me = this;
      getDocumentById(id)
        .then(({ data }) => {
          this.dataPrint = data
          this.print()
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error.");
        });
    },
    save() {
      var totalIva = 0;
      this.refund_data.detail.forEach((el) => {
        totalIva = el.iva + totalIva;
      });
      var data = {
        document: {
          type_id: this.refund_type.id,
          type_pivot_id: this.refund_type.type_pivot_id,
          user_id: this.user.usuario_id,
          electronic: false,
          terceros_id: this.refund_data.document.person.id,
          vendedor_id: this.refund_data.document.seller.id,
          crossing_document: this.refund_data.document.id,
          sucursal_id: this.user.sucursal_id,
          observacion: this.refund_data.observation,
          return_money: this.refund_data.return_money,
          return_money_selected: this.refund_data.return_money_selected,
          valor_iva: totalIva,
        },
        detail: this.refund_data.detail,
        data_pos: this.dataPos.computer,
      };
      saveRefund(data)
        .then(({ data }) => {
          if (data.code === 200) {
            this.print_btn = true;
            this.dataPrint = data.data;
            this.print();
          } else {
            console.log(data.error);
            this.$message.error("Error: " + data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error");
        });
    },
    newRegister() {
      this.active = 0;
      this.print_btn = false;
      this.type_document = null;
      this.data_document = null;
      this.renderComponentView();
    },
    next(data) {
      if (this.validation()) {
        if (this.active++ > 2) this.active = 0;
        this.renderComponentView();
      } else {
        this.$message({
          message: this.errorMessage,
          type: "warning",
        });
      }
    },
    previous() {
      if (this.active-- > 2) this.active = 0;
      this.renderComponentView();
    },
    renderComponentView() {
      if (this.active === 0) {
        this.renderComponent = "step1";
        this.data = this.type_document;
      }
      if (this.active === 1) {
        this.renderComponent = "step2";
      }
      if (this.active === 2) this.renderComponent = "step3";
    },
    validation() {
      var resp = true;
      if (this.active === 0) {
        if (this.type_document === null || this.type_document === "") {
          this.errorMessage = "Atención!, Completa los campos para continuar.";
          resp = false;
        }
      }

      if (this.active === 1) {
        if (this.data_document !== null) {
          if (this.data_document.detail.length == 0) {
            this.errorMessage = "Selecciona productos de la factura.";
            resp = false;
          } else {
            if (this.data_document.observation == "") {
              this.errorMessage = "Ingresa una observación.";
              resp = false;
            }else{
              if(this.data_document.return_money && this.data_document.return_money_selected === ""){
                this.errorMessage = "Selecciona una forma de pago";
                resp = false;
              }
            }
          }
        } else {
          this.errorMessage = "Debes buscar una factura para continuar.";
          resp = false;
        }
      }

      if (this.active === 2) {
        console.log("entro 2");
      }
      return resp;
    },
    startConnectionPrint() {
      let me = this;
      setTimeout(() => {
        // Authentication setup ///
        var privateKey =
          "-----BEGIN PRIVATE KEY-----\n" +
          "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCn13RTAjw4IfTw\n" +
          "d46mk3Lw7YLFJbIGdZvV4ieg51+fqqyUk81+q0icBIAnsrg3j2J+oBlwd1HLgaeI\n" +
          "C+8tEVn3ed9tzvxhJ5iGmd9DUorPIP2RsX+62uKi7OV4fXYBkqSXKWdaoI0nLEwf\n" +
          "FkCL7b8UN3LUazECIsK4bXeYIFMgAERYr6lvtP8HTJ3t6d/W0BWNQgXpUMuGJhvo\n" +
          "5yiJhsVZp8YjF/xyBcQRZjx58Ze4n426dM4lT5cH7EDJk5iogL/9D5YmPZ8dFMVZ\n" +
          "yaAfVVvT1x3vC7GqboKbYISNZLyVM3/+F3ikkcGe/NLbKUwdnFc+rKOdGp/Dup8T\n" +
          "HWVpm9MfAgMBAAECggEAGV2Z8mudjzwuiOMAt5MEJX43q7TZNVmz3fyqUYp2LKq+\n" +
          "H0xBG7omJJIXjihX5+mvEIUAozsdLPzgxVJMaMq9BoYwKqfEA6jYx8rcb4+7c7dA\n" +
          "KzMgoywuYUIdIfsQCQfRARlcSdmGy5tJu88AnUUmghcpLwouI2z+caH1l5OXDHhP\n" +
          "LbN+opDi0EhCd+PoNInkpj9s12/tl8rOOlHJSGUSgIAZGyafS5llBm3JoO4dZUmQ\n" +
          "0Lq1tKI25ai7B7EtZ+3Dyvya6N+5ACXucwlsufROc4kLrGbAffEPkreHydD78iSf\n" +
          "d1VMUuDABnUawPOLnd3yKBwAfvxN6jhjlYThw7rNRQKBgQDVX17U7H+IZsbvbknT\n" +
          "XsJQJlE+fdUYI6EImBDs9MG34akpTi+MdgdKJOKTJIVQIm5pxOD1nxzrY1vlNRML\n" +
          "hA8o0ZACcsION/476puRfG2LhxVak+gN+P0NlWXY4R1QsLSsc/KaL1MoBO2lom7+\n" +
          "C9l+KIG544ZzfK/hirVhPKQQZQKBgQDJX3iMS37VLu+2OQ26L9xstH33C9R73DJJ\n" +
          "mpPXra1KA1hgPYETy7769LMdYV7vKsyh4++4VgWAmSLkOK0H/ViQpmcW+kyyLQ9w\n" +
          "euD87zRcjIIY0ehI8JyLHX9Dxcuy4/oJDn+0IRXXkHDfSrw6kfkFuP+khTSRbbAj\n" +
          "q0L7vyfjMwKBgCwC5f6pYYzzFimg6B1vOElr/eoT7nZyMu8g3qzht2uEYm4gsHIa\n" +
          "jlpezVVofQ8QaxHqCDThwwdSkUvv7KO9c5sJztiSbzCboBcfx0L1FWtoS6FJTN5E\n" +
          "831vyXg4xuEz8bB4ohvnkxF4IsS9goNoUk5RcKC1K7B6kZTGFa+e4IB5AoGAccGK\n" +
          "+cYUKh3PsKLeTFWJMadsmUiHikjD56x45Bfqkqr9G7Di3+nzQxVEsc41sOn+m12G\n" +
          "KB4AEbtwaHhT1vWZOG2oQuS2utiVpjbkLj561+VQAPFjRQEXjcbmA8US40XCE6er\n" +
          "IHioPBQOjzVKy9N2V3NAhxHwtk6+eAfhWRGNWN0CgYA6XAiF1Al+kYkosSKsjpxo\n" +
          "BdKzT+5vCw37Iq3F+DYxzRb8MKx9XpeGoEWbG62Xez7eZ2B6UaARGDu43Ioa/6vk\n" +
          "bh9647T6nehoGlagzdu92kO5FCFsoIB2pBBZAS5k7S8jtSS7To2W3FDY/o3wwIRg\n" +
          "spuV+871lajMrXi3/faa/w==\n" +
          "-----END PRIVATE KEY-----";

        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise(function (toSign) {
          return function (resolve, reject) {
            try {
              var pk = KEYUTIL.getKey(privateKey);
              var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
              sig.init(pk);
              sig.updateString(toSign);
              var hex = sig.sign();
              // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
              resolve(stob64(hextorstr(hex)));
            } catch (err) {
              console.error(err);
              reject(err);
            }
          };
        });
        qz.security.setCertificatePromise(function (resolve, reject) {
          resolve(axios.get(process.env.VUE_APP_ROOT_API + '/certs/getQzCertificate').then(response => response.data))
        });
        if (!qz.websocket.isActive()) {
          qz.websocket.connect().then(function () {
            console.log("Connected QZ-tray! ", qz.websocket.isActive());
            me.$store.commit("SET_PRINTER_CONNECT", true);
            me.$store.commit("SET_PRINTER_RECONNECT", false);
            qz.printers
              .getDefault()
              .then(function (data) {
                me.$store.commit("SET_PRINTER", data);
              })
              .catch(function (error) {
                console.log("error: ", error);
              });
          });
        } else {
          me.$message({
            message: "La conección con la impresora, ya ha sido establecida.",
            type: "success",
          });
          me.$store.commit("SET_PRINTER_RECONNECT", false);
        }
      }, 2000);
    },
  },
  created() {
    this.startConnectionPrint();
    this.$store.commit("SET_RETURN_MONEY", false);
  },
};
</script>

<style lang="scss" scoped>
.page-header.card-base {
  padding: 10px;
}
.page-header {
  margin-bottom: 25px;
  h1 {
    button {
      float: right;
      margin-top: 5px;
    }
  }
}
.btn-steps {
  text-align: center;
}
.content-form {
  padding-top: 20px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  .content-form {
    height: auto;
  }
}
</style>
