const getters = {
  layout(state, getters) {
    return state.layout
  },
  navPos(state, getters) {
    return state.layout.navPos
  },
  toolbar(state, getters) {
    return state.layout.toolbar
  },
  footer(state, getters) {
    return state.layout.footer
  },
  boxed(state, getters) {
    return state.layout.boxed
  },
  roundedCorners(state, getters) {
    return state.layout.roundedCorners
  },
  viewAnimation(state, getters) {
    return state.layout.viewAnimation
  },
  isLogged(state, getters) {
    return state.logged
  },
  splashScreen(state, getters) {
    return state.splashScreen
  },
  printerConnect: 	state => state.document.printerConnect,
  printerReConnect: 	state => state.document.printerReConnect,
  // DOCUMENTS
  document: 	state => state.document.document,
  detail: 	  state => state.document.detail,
  client: 	  state => state.document.client,
  payment: 	  state => state.document.payment,
  // view
  document_view: 	state => state.document.document_view,
  detail_view: 	state => state.document.detail_view,
  btn_new: 	state => state.document.btn_new,
  btn_saved: 	state => state.document.btn_saved,
  getDailySale: 	state => state.document.getDailySale,
  
  printer_default: state => state.document.printer_default,
  id_document: state => state.document.id_document,

  // REFOUND
  refund_type: state => state.refund.typeDocument,
  refund_data: state => state.refund.data,
  return_money: state => state.refund.returnMoney,
  
  // DRAWER
  open_drawer: state => state.drawer.open_drawer,
  action_drawer: state => state.drawer.action_drawer,
  data: state => state.drawer.data,

  // Expenses
  expenses_detail: state => state.expenses.detail,

 }
 export default getters