<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="12" :xs="24" :sm="24" :md="14" :lg="14" :xl="14">
          <h2>Crear nuevo pago</h2>
          <el-form :model="form" ref="form" status-icon label-width="110px" size="medium" class="form">
            <el-form-item label="Fecha" prop="date_pay" :rules="[{ required: true, message: 'Campo Obligatorio' }]">
              <el-date-picker type="date" v-model="form.date_pay" autocomplete="off" format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"></el-date-picker>
            </el-form-item>
            <el-form-item label="Proveedor" prop="provider_id"
              :rules="[{ required: true, message: 'Campo Obligatorio' }]">
              <el-select v-model="form.provider_id" clearable placeholder="Proveedor" filterable >
                <el-option v-for="item in providers" :key="item.id" :label="item.nombre" :value="item.id">
                </el-option>
              </el-select>
              <el-alert
                title="Para registrar un nuevo proveedor, ve al menu 'Terceros'"
                type="info"
                :closable="false"
                show-icon>
              </el-alert>
            </el-form-item>

            <!-- Tabla de detalle -->
            <h3 style="text-align: center;">Detalle del pago</h3>
            <el-form-item>
              <el-table :data="form.detail" style="width: 100%">
                <el-table-column prop="provider_payment_type_id" label="Tipo de Pago">
                  <template slot-scope="scope">
                    <el-form-item :prop="'detail.' + scope.$index + '.provider_payment_type_id'"
                      :rules="[{ required: true, message: 'Campo Obligatorio', trigger: 'change' }]">
                      <el-select v-model="scope.row.provider_payment_type_id" placeholder="Tipo de Pago">
                        <el-option v-for="type in paymentTypes" :key="type.id" :label="type.description"
                          :value="type.id"></el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="value_paid" label="Valor Pagado">
                  <template slot-scope="scope">
                    <el-form-item :prop="'detail.' + scope.$index + '.value_paid'"
                    :rules="[{ required: true, message: 'Campo Obligatorio', trigger: 'blur' }]">
                      <el-input v-model="scope.row.value_paid" placeholder="Valor Pagado" type="number"></el-input>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="invoice" label="Factura">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.invoice" placeholder="Factura"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="description" label="Descripción">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.description" placeholder="Descripción"></el-input>
                  </template>
                </el-table-column>
                <el-table-column label="Acciones">
                  <template slot-scope="scope">
                    <el-button v-if="form.detail.length > 1" @click="removeDetail(scope.$index)" size="mini"
                      type="danger" icon="el-icon-delete" circle></el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-button @click="addDetail" size="mini" type="text" icon="el-icon-plus">Agregar Detalle</el-button>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="submitForm('form')" :loading="loading" icon="el-icon-check"
                v-if="!edit">Guardar</el-button>
              <el-button type="warning" @click="submitForm('form')" :loading="loading" icon="el-icon-edit"
                v-else>Actualizar</el-button>
              <el-button @click="resetForm('form')" icon="el-icon-close">Cancelar</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <!-- grid -->

        <el-col :span="12" :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-date-picker placeholder="Fecha" v-model="date" clearable size="medium" type="date" format="yyyy/MM/dd"
              value-format="yyyy-MM-dd" @clear="date = null;getAll();">
              </el-date-picker>
            </el-col>
            <el-col :span="6">
              <el-input placeholder="Consecutivo" v-model="consecutive" clearable size="medium" @clear="consecutive = null;getAll();">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-select v-model="provider_id" clearable placeholder="Proveedor" filterable  @clear="provider_id = null;getAll();"">
                <el-option v-for="item in providers" :key="item.id" :label="item.nombre" :value="item.id">
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="6">
              <el-button type="primary" icon="el-icon-search" size="medium" :loading="loadingFilter"
                @click="filterTable">Buscar</el-button>
              <!-- <el-button type="warning" icon="el-icon-download" size="medium"
                @click="modalOpen = true">Descargar</el-button> -->
            </el-col>
          </el-row>
          <el-table :data="tableData" v-loading="loadingTable" style="width: 100%; margin-top: 20px" height="530">
            <el-table-column type="expand">
              <template slot-scope="props">
                <el-table :data="props.row.detail" style="width: 100%">
                  <el-table-column label="Fecha" prop="date" sortable></el-table-column>
                  <el-table-column label="Factura" prop="invoice" sortable></el-table-column>
                  <el-table-column label="Descripción" prop="description" sortable></el-table-column>
                  <el-table-column label="Tipo de pago" prop="provider_payment_types" sortable>
                    <template slot-scope="scope">
                      <span>{{ scope.row.provider_payment_types.description }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="Valor" prop="value_paid" sortable>
                    <template slot-scope="scope">
                      <span>${{ formatNumber(scope.row.value_paid) }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
            <el-table-column label="Fecha" prop="date" sortable></el-table-column>
            <el-table-column label="# Pago" prop="consecutive" sortable></el-table-column>
            <el-table-column label="Proveedor" prop="person.nombre" sortable></el-table-column>
            <el-table-column width="100px" align="right">
              <template slot-scope="scope">
                <!-- <el-tooltip class="item" effect="dark" content="Editar" placement="top">
                  <el-button size="mini" type="warning" @click="handleEdit(scope.$index, scope.row)" icon="el-icon-edit"
                    circle></el-button>
                </el-tooltip> -->
                <el-tooltip class="item" effect="dark" content="Ver" placement="top">
                  <el-button size="mini" type="primary" @click="handleView(scope.$index, scope.row)" icon="el-icon-view"
                    circle></el-button>
                </el-tooltip>
                <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                  <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
                    icon="el-icon-delete" circle></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="tableData.length > 0" class="pagination-box animated fadeInRight"
            style="text-align: end; padding-top: 10px">
            <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
              @current-change="handleCurrentPage"></el-pagination>
          </div>
        </el-col>
      </el-row>

      <modal-view-payment
        :openModalView="modalViewOpen"
        :dataModalView="dataView"
        @close="closeModal($event, true)"
      ></modal-view-payment>
    </div>
  </div>
</template>

<script>
import ModalViewPayment from "@/views/apps/paymentSuppliers/ModalView.vue";
import { getUser } from "@/utils/auth";
import { actualDate } from "@/utils/date";
import { formatInt, formatNumber } from "@/utils/number";
import { getAll, store, storeDetail, update, getById, getPaymentTypes, deleteData } from "@/api/providerPayment";
import { getAll as getAllProvider, getByType } from "@/api/person";
export default {
  name: "PaymentSuppliers",
  components: {
    ModalViewPayment,
  },
  data() {
    return {
      user: getUser(),
      loading: false,
      loadingTable: false,
      loadingFilter: false,
      modalViewOpen: false,
      modalProviderOpen: false,
      edit: false,
      dataProvider: null,
      dataView: null,
      tableData: [],
      providers: [],
      paymentTypes: [],
      consecutive: '',
      date: '',
      provider_id: '',
      form: {
        date_pay: actualDate().split(' ')[0],
        provider_id: "",
        detail: [
          {
            provider_payment_type_id: "",
            value_paid: "",
            invoice: "",
            description: ""
          }
        ]
      },
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    closeModalProvider(data){
      this.modalProviderOpen = false
      this.dataProvider = null
    },
    closeModal(data){
      this.modalViewOpen = false
      this.dataView = null
    },
    addDetail() {
      this.form.detail.push({
        provider_payment_type_id: '',
        value_paid: '',
        invoice: '',
        description: ''
      });
    },
    removeDetail(index) {
      this.form.detail.splice(index, 1);
    },
    handleCurrentPage(val) {
      this.getAll(val);
    },
    filterTable() {
      this.loadingFilter = true;
      this.getAll();
    },
    getAll(current) {
      this.loadingTable = true;
      if (typeof current == "undefined") {
        if (this.consecutive != null) {
          this.currentPage = 1;
        }
      } else {
        this.currentPage = current;
      }
      getAll(this.pageSize, this.currentPage, {
        consecutive: this.consecutive,
        date: this.date,
        provider_id: this.provider_id,
      })
        .then(({ data }) => {
          this.tableData = data.data.data;
          this.total_rows = data.data.total;
          this.loadingTable = false;
          this.loadingFilter = false;
        })
        .catch(function (error) {
          this.loadingTable = false;
          this.loadingFilter = false;
          console.log(error);
        });
    },
    submitForm(formName) {
      this.loadingTable = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.edit) {
            this.updateForm();
          } else {
            this.saveForm();
          }
        } else {
          this.loadingTable = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      let me = this;
      store(me.form)
        .then(({ data }) => {
          me.loadingTable = false;
          console.log(data.code);
          if (data.code === 200) {
            me.getAll();
            me.resetForm("form");
          } else {
            me.$message.error("Error! " + data.error);
          }
        })
        .catch(function (error) {
          me.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    updateForm() {
      update(this.form)
        .then(({ data }) => {
          if (data.code === 200) {
            this.getAll();
            this.resetForm("form");
            this.loadingTable = false;
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form.detail = [
        {
          provider_payment_type_id: "",
          value_paid: "",
          invoice: "",
          description: ""
        }
      ]
      this.edit = false;
      this.loading = false;
      this.loadingTable = false;
    },
    handleEdit(index, row) {
      this.edit = true;
      this.form.id = row.id;
      this.form.documento = row.documento;
      this.form.nombre = row.nombre;
      this.form.direccion = row.direccion;
      this.form.ciudad = row.ciudad;
      this.form.email = row.email;
      this.form.telefono = row.telefono;
      this.form.nacimiento = row.nacimiento;
      if (row.cliente == 1) {
        this.form.cliente = true;
      } else {
        this.form.cliente = false;
      }
      if (row.vendedor == 1) {
        this.form.vendedor = true;
        this.sellerType = true;
      } else {
        this.form.vendedor = false;
        this.sellerType = false;
      }
      if (row.proveedor == 1) {
        this.form.proveedor = true;
      } else {
        this.form.proveedor = false;
      }
      this.form.cargo = row.cargo;
    },
    handleDelete(index, row) {
      this.loadingTable = true;
      deleteData(row.id)
        .then(({ data }) => {
          if (data.code === 200) {
            this.getAll();
            this.resetForm("form");
          } else {
            this.loadingTable = false;
            this.$message.error("Error! " + data.error);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    loadProviders() {
      getByType('proveedor')
        .then(({ data }) => {
          this.providers = data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    loadPaymentTypes() {
      getPaymentTypes()
        .then(({ data }) => {
          this.paymentTypes = data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleView(index, row) {
      this.modalViewOpen = true;
      this.dataView = row;
    },
    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(formatInt(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
  },
  created() {
    let me = this
    this.getAll();
    this.loadProviders();
    this.loadPaymentTypes();
    setTimeout(() => {
      me.form.branchId = me.user.sucursal_id
    }, 2000);
  },
};
</script>
<style lang="scss" scope>
.form {
  margin-top: 15px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>