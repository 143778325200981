import request from '@/utils/request'

export function getKardex(page_size, currentPage, branch, product) {
  return request({
    url: 'kardex/getKardex/' + page_size + '?page=' + currentPage,
    method: 'get',
    params: {
      'branch':branch, 
      'product':product
    }
  })
}

export function dowloadInventory(data) {
  return request({
    url: 'kardex/dowloadInventory',
    method: 'post',
    data,
    responseType: 'blob'
  });
}