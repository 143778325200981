<template>
  <vue-scroll class="register-base-cash align-vertical">
    <div class="form-wrapper align-vertical-middle">
      <div
        class="form-box card-base card-shadow--extraLarge"
        v-loading="loadingTransfers"
        element-loading-text="Comprobando traslados o remisiones..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
      >
        <img class="image-logo" src="@/assets/images/logo.svg" alt="logo" />
        <template>
          <div v-show="transfersDocuments.length <= 0">
            <float-label class="styled">
              <input
                type="email"
                placeholder="Ingresa la base de caja $00"
                v-model="cashBase"
                ref="base_cash"
                :disabled="disabledCash"
              />
            </float-label>
            <float-label class="styled">
              <label for>
                <i class="mdi mdi-desktop-mac"></i> Selecciona el computador
              </label>
              <el-select
                v-model="computerSelected"
                placeholder="Seleccione"
                value-key="id"
              >
                <el-option
                  v-for="item in computers"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </float-label>

            <float-label class="styled">
              <label for>
                <i class="mdi mdi-account-group-outline"></i> Selecciona los
                vendedores de hoy
              </label>
              <el-select
                v-model="sellersSelected"
                multiple
                placeholder="Seleccione"
                value-key="id"
                :disabled="disabledSelectedSellers"
              >
                <el-option
                  v-for="item in sellers"
                  :key="item.id"
                  :label="item.seller != null ? item.seller.nombre : ''"
                  :value="item"
                ></el-option>
              </el-select>
            </float-label>
          </div>
        </template>

        <el-alert
          :closable="false"
          title="Acepta los traslados pendientes para continuar."
          type="warning"
          :effect="effect"
          show-icon
          v-show="transfersDocuments.length > 0"
        >
        </el-alert>

        <el-table
          v-show="transfersDocuments.length > 0"
          :data="transfersDocuments"
          :height="heightTransfer"
          max-height="250"
          style="width: 100%"
          size="mini"
          empty-text="No hay traslados pendientes"
        >
          <el-table-column prop="consecutivo" label="Consecutivo" width="100">
          </el-table-column>
          <el-table-column prop="branch.razon_social" label="Sucursal Origen">
            <template slot-scope="scope">
              <div style="font-size: 16px;">{{ scope.row.branch.razon_social }}</div>
              <small style="opacity: 0.8;">{{ scope.row.type.descripcion }}</small>
            </template>
          </el-table-column>
          <el-table-column width="70">
            <template slot-scope="scope">
              <el-tooltip
                class="item"
                effect="dark"
                content="Aceptar Traslado"
                placement="top"
              >
                <el-button
                  size="mini"
                  type="success"
                  icon="el-icon-check"
                  circle
                  @click="acceptTransfer(scope.$index, scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <div
          class="flex text-center center pt-20 pb-10"
          v-show="transfersDocuments.length <= 0"
        >
          <el-button
            type="success"
            size="small"
            @click="registerCash"
            class="send-btn tada animated"
            :icon="loading ? 'el-icon-loading' : 'el-icon-circle-check'"
            >Registrar Base</el-button
          >
        </div>
      </div>
    </div>
  </vue-scroll>
</template>
<script>
import { findBaseCash } from "@/api/login";
import { getSeller } from "@/api/person";
import { registerCash, getIncomingTransfers } from "@/api/branch";
import { acceptTransfer } from "@/api/document";
import { getUser, setBaseCash, setDataPos } from "@/utils/auth";
import { actualDate } from "@/utils/date";
import { getDocument, startConnectionPrint } from "@/utils/print/transfer";
export default {
  name: "BaseCash",
  data() {
    return {
      user: getUser(),
      cashBase: "",
      computers: [],
      computerSelected: 1,
      sellers: [],
      sellersSelected: [],
      loading: false,
      disabledCash: false,
      loadingTransfers: false,
      transfersDocuments: [],
      effect: "light",
      heightTransfer: 200,
      dataPrintTransfer: [],
      disabledSelectedSellers: false,
    };
  },
  methods: {
    registerCash() {
      let me = this;
      me.loading = true;
      var computer = this.computers.find(
        (element) => element.id === this.computerSelected
      );
      var datas = {
        sucursal_id: this.user.branch.id,
        user_id: this.user.id,
        fecha: actualDate(),
        valor_caja: this.cashBase,
        computer: computer,
        sellers: this.sellersSelected,
      };
      if (this.cashBase == "") {
        this.$message({
          message: "Atención!, Ingresa un valor en la base de caja.",
          type: "warning",
        });
        this.$nextTick(() => this.$refs.base_cash.focus());
        this.loading = false;
      } else {
        if (this.transfersDocuments.length > 0) {
          this.$message({
            message:
              "Atención!, Acepta todos los traslados o remisiones para continuar.",
            type: "warning",
          });
          this.loading = false;
          this.effect = "dark";
          this.changeEffectMessage();
        } else {
          registerCash(datas)
            .then(({ data }) => {
              if (data.code === 200) {
                this.$message({
                  type: "success",
                  message: data.message,
                });
                setBaseCash(JSON.stringify(data.data));
                setDataPos(JSON.stringify(datas));
                me.loading = false;
                setTimeout(() => {
                  me.$router.push({ path: "/pos" });
                }, 2000);
              } else {
                me.loading = false;
                this.$message({
                  type: "error",
                  message: "Error: " + data.error,
                  showClose: true,
                  duration: 10000,
                });
              }
            })
            .catch(function (error) {
              me.loading = false;
              console.log(error);
            });
        }
      }
    },
    findBaseCashMethod() {
      let me = this;
      findBaseCash({ id: me.user.branch.id })
        .then(({ data }) => {
          if (data) {
            me.cashBase = data.valor_caja;
            me.disabledCash = true;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getComputers() {
      var metaStore = this.user.branch.meta_data;
      this.computers = JSON.parse(metaStore).computers;
    },
    getSeller() {
      let me =this
      me.disabledSelectedSellers = false
      getSeller(this.user.branch.id)
        .then(({ data }) => {
          this.sellers = data;
          if (this.user.vendedor_id) {
            data.forEach((element) => {
              if(element.seller.id === parseInt(me.user.vendedor_id)){
                me.sellersSelected.push(element)
                me.disabledSelectedSellers = true
              }
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getTransfers() {
      let me = this;
      me.loadingTransfers = true;
      getIncomingTransfers({branch_id: this.user.branch.id})
        .then(({ data }) => {
          me.loadingTransfers = false;
          me.transfersDocuments = data; // DEBO COLOCAR EL DATA EN VEZ DE []
        })
        .catch(function (error) {
          me.loadingTransfers = false;
          console.log(error.response);
        });
    },
    changeEffectMessage() {
      let me = this;
      setInterval(() => {
        me.effect = "light";
      }, 3000);
    },
    acceptTransfer(idx, row) {
      var dataTransfer = {
        documento_id: row.id,
        tipo_id: row.tipo_id,
        bodega_id: this.user.branch.id,
      };
      var me = this;
      this.$swal
        .fire({
          title: "<strong>Acepto a conformidad el documento!</strong>",
          icon: "warning",
          html:
            "Confirmo que he verificado los productos y cantidades recibidas.",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          focusConfirm: false,
          showLoaderOnConfirm: true,
          confirmButtonText:
            '<i class="mdi mdi-clipboard-check-outline"></i> Aceptar Traslado!',
          cancelButtonText:
            '<i class="mdi mdi-close-circle-outline"></i> Cerrar',
          preConfirm: () => {
            return acceptTransfer(dataTransfer)
              .then(({ data }) => {
                return data;
              })
              .catch(function (error) {
                me.$swal.showValidationMessage(
                  `Error: ${error.response.data.message}`
                );
              });
          },
          allowOutsideClick: () => !me.$swal.isLoading(),
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: "success",
              title: "Perfecto!",
              text: "Documento generado y aceptado con exito!",
            });
            this.dataPrintTransfer = row;
            this.print(idx);
          }
        })
        .catch(function (error) {
          me.$swal.showValidationMessage(
            `Error: ${error.response.data.message}`
          );
        });
    },
    print(idx) {
      let me = this;
      getDocument({id:this.dataPrintTransfer.id, branch_id:this.user.sucursal_id})
        .then((data) => {
          me.transfersDocuments.splice(idx, 1);
          console.log("document print successfull");
        })
        .catch(function (error) {
          console.log(error);
          me.$swal.fire({
              icon: "warning",
              title: "Atención!",
              text: `Error:  ${error}`,
            });
        });
    },
  },
  created() {
    this.getTransfers();
    startConnectionPrint();
    this.findBaseCashMethod();
    this.getComputers();
    this.getSeller();
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/_variables";

.register-base-cash {
  background: $text-color;
  margin-left: -30px;
  margin-right: -30px;

  .form-wrapper {
    width: 100%;
  }

  .form-box {
    width: 100%;
    max-width: 450px;
    padding: 30px;
    box-sizing: border-box;
    margin: 20px auto;

    a {
      font-size: 14px;
      color: $text-color-accent;
      text-decoration: none;
      font-weight: 500;
    }

    .image-logo {
      width: 200px;
      margin: 0px auto;
      margin-bottom: 50px;
      display: block;
    }

    .send-btn {
      width: 160px;
    }

    .vfl-has-label.styled {
      max-width: 100% !important;
    }
  }
}

@media (max-width: 768px) {
  .layout-container .container .view.register-base-cash {
    margin-left: -5px;
    margin-right: -5px;
    max-width: calc(100% + 10px);
  }
}
</style>
