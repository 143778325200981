<template>
  <div class="login-page flex">
    <div class="box grow scrollable align-vertical side-box box-left">
      <div class="align-vertical-middle wrapper text-center">
        <img
          class="image-logo"
          src="@/assets/images/logo.svg"
          alt="logo-left"
        />
        <br>
        <img
          class="image-logo-company"
          src="@/assets/images/logo_.png"
          alt="logo-left"
        />
        <!-- <h1 class="h-big">YAMAHMOTOS</h1> -->
        <!-- <p class="p-50">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus ullamcorper nisl erat, vel convallis elit fermentum pellentesque. Sed mollis velit facilisis facilisis viverra.</p> -->
      </div>
    </div>
    <div class="box grow scrollable align-vertical side-box box-right">
      <div class="align-vertical-middle wrapper">
        <form class="form-box" @submit.prevent="login">
          <h2><i class="mdi mdi-account-key"></i> Ingrese a su cuenta</h2>
          <!-- <el-link type="primary" href="https://pos.2factura.com/app/login">CLICK AQUI PARA REDIRECCIONAR AL SISTEMA</el-link> -->

          <float-label class="styled">
            <input
              type="username"
              name="username"
              placeholder="Nombre de Usuario"
              v-model="username"
            />
          </float-label>
          <float-label class="styled">
            <input
              type="password"
              name="password"
              placeholder="Contraseña"
              v-model="password"
            />
          </float-label>
          <el-alert
            v-show="error"
            title="Error al iniciar sesión"
            type="error"
            show-icon
            :description="errorMsg"
            @close="closeMessageError"
          ></el-alert>
          <div class="flex text-center center pt-30 pb-10">
            <el-button
              plain
              size="small"
              native-type="submit"
              class="login-btn pulse animated themed"
              @click="login"
              :loading="loading"
              >Entrar</el-button
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { login, findBaseCash, getConfig, findPrincipalBranch } from "@/api/login";
import { setToken, setDemo, setUser, setConfig, setPrincipalBranch } from "@/utils/auth";

export default {
  name: "Login",
  data() {
    return {
      baseUrl: process.env.VUE_APP_ROOT,
      username: (process.env.VUE_APP_ROOT === 'http://localhost:8000/') ? "admin@admin.com" : "",
      password: (process.env.VUE_APP_ROOT === 'http://localhost:8000/') ? "Miami2013$" : "",
      loading: false,
      error: false,
      errorMsg: "",
      splashScreen: false,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      if (this.username != "" && this.password != "") {
        await login(this.username, this.password, false)
          .then(async ({ data }) => {
            if (data.code === 200) {
              await this.systemConfiguration()
                .then(async (resp) => {
                  setToken(data.access_token);
                  setUser(data.user);
                  if (
                    this.baseUrl === "https://demoinfashionpos.4factura.com/"
                  ) {
                    setDemo();
                  }
                  this.$store.commit("setLogin");
                  await this.findBaseCashMethod(data.user);
                  await this.findPrincipalBranchSet();
                })
                .catch((resp) => {
                  console.log(resp);
                });
            } else {
              this.error = true;
              this.errorMsg = "Credenciales invalidas";
            }
            this.loading = false;
          })
          .catch((response) => {
            this.error = true;
            this.loading = false;
            // this.errorMsg = error.errors
            console.log(response);
          });
      } else {
        this.error = true;
        this.errorMsg = "Compelte los campos por favor";
        this.loading = false;
      }
    },
    async systemConfiguration() {
      var config = false;
      await getConfig()
        .then(({ data }) => {
          setConfig(JSON.stringify(data));
        })
        .catch((response) => {
          console.log(response);
        });
        return config;
    },
    async findBaseCashMethod(req) {
      let user = JSON.parse(req);
      await findBaseCash({ id: user.branch.id })
        .then(({ data }) => {
          for (var i = 0; i < user.roles.length; i++) {
            if (user.roles[i].redirect_to) {
              // this.$router.push({ path: user.roles[i].redirect_to });
              // if (data) {
              // setBaseCash(JSON.stringify(data));
              // location.reload();
              // } else {
              //   window.location.href = "/app/base_cash";
              // }
            } else {
              this.$router.push({ path: "/404" });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async findPrincipalBranchSet(){
      await findPrincipalBranch()
        .then(({ data }) => {
          setPrincipalBranch(JSON.stringify(data));
          location.reload();
        })
    },
    closeMessageError() {
      this.error = false;
    },
  },
  mounted() {
    // demo
    if (this.baseUrl === "https://demoinfashionpos.4factura.com/") {
      this.$notify.info({
        title: "Demo POS",
        message: "Estas en una version demo del sistema original.",
        showClose: false,
        position: "bottom-left",
        duration: 0,
      });
    }
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/_variables";

.login-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 !important;

  .side-box {
    .wrapper {
      width: 100%;
      box-sizing: border-box;
      padding: 20px;
    }
  }

  .box-left {
    background-image: url("../../../assets/images/login1.png");
    background-size: cover;
    background-position: 50% 50%;

    .wrapper {
      .image-logo, .image-logo-company {
        width: 90%;
        max-width: 400px;
        margin-top: 20px;
      }
    }
  }
  .box-right {
    background: white;
    color: var(--background-color);
  }

  .form-box {
    width: 100%;
    max-width: 340px;
    padding: 30px;
    box-sizing: border-box;
    margin: 20px auto;
    //background: transparent;

    a {
      font-size: 14px;
      color: var(--color-accent);
      text-decoration: none;
      font-weight: 500;
    }

    .image-logo {
      width: 80px;
      margin: 0px auto;
      margin-bottom: 70px;
      display: block;
    }

    .login-btn {
      background: rgba(var(--color-accent-rgb), 0.2);
      color: var(--text-color);
      border-color: var(--text-color);
      border-width: 2px;
      font-weight: bold;
      border-radius: 0;

      &:hover {
        background: rgba(var(--color-accent-rgb), 1);
        border-color: var(--text-color);
        color: var(--text-color);
      }
    }
  }
}

@media (max-width: 1200px) {
  .login-page {
    .box-left {
      .wrapper {
        .h-big {
          font-size: 50px;
        }
      }
    }
  }
}
@media (max-width: 900px) {
  .login-page {
    .box-left {
      .wrapper {
        .h-big {
          font-size: 30px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .login-page {
    display: block;
    overflow: auto;

    .side-box {
      display: block;
    }
  }
  .image-logo-company{
    width: 50% !important;
  }
}
</style>
