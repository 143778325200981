<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="10" :xl="10">
          <h2>Datos de registro</h2>
          <el-form
            :model="form"
            ref="form"
            status-icon
            label-width="110px"
            size="medium"
            class="form"
          >
            <el-form-item
              label="Documento/Nit"
              prop="documento"
              :rules="[{ required: true, message: 'Campo Obligatorio' }]"
            >
              <el-input
                type="text"
                v-model="form.documento"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Nombre"
              prop="nombre"
              :rules="[{ required: true, message: 'Campo Obligatorio' }]"
            >
              <el-input
                type="text"
                v-model="form.nombre"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Dirección"
              prop="direccion"
              :rules="[{ required: true, message: 'Campo Obligatorio' }]"
            >
              <el-input
                type="text"
                v-model="form.direccion"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Ciudad"
              prop="ciudad"
              :rules="[{ required: true, message: 'Campo Obligatorio' }]"
            >
              <el-input
                type="text"
                v-model="form.ciudad"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="Email"
              prop="email"
            >
              <el-input
                type="mail"
                v-model="form.email"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Teléfono" prop="telefono">
              <el-input
                type="text"
                v-model="form.telefono"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="Nacimiento" prop="nacimiento">
              <el-date-picker
                v-model="form.nacimiento"
                type="date"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
                placeholder="Selecciona"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="">
              <el-checkbox v-model="form.cliente">Cliente</el-checkbox>
              <el-checkbox
                v-model="form.vendedor"
                @change="sellerType = !sellerType"
                >Vendedor</el-checkbox
              >
              <el-checkbox v-model="form.proveedor">Proveedor</el-checkbox>
            </el-form-item>
            <!-- <transition name="fade">
              <el-form-item
                v-if="sellerType"
                label="Cargo"
                prop="cargo"
                :rules="[
                  {
                    required: true,
                    message: 'Digite el cargo de este vendedor',
                  },
                ]"
              >
                <el-input
                  type="text"
                  v-model="form.cargo"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </transition> -->
            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('form')"
                :loading="loading"
                icon="el-icon-check"
                v-if="!edit"
                >Guardar</el-button
              >
              <el-button
                type="warning"
                @click="submitForm('form')"
                :loading="loading"
                icon="el-icon-edit"
                v-else
                >Actualizar</el-button
              >
              <el-button @click="resetForm('form')" icon="el-icon-close"
                >Cancelar</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <!-- grid -->

        <el-col :span="12" :xs="24" :sm="24" :md="12" :lg="14" :xl="14">
          <el-row :gutter="20">
            <el-col :span="9">
              <el-input
                placeholder="Número de documento"
                v-model="docuemnt"
                clearable
                size="medium"
                @clear="
                  docuemnt = null;
                  getAll();
                "
              >
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </el-col>
            <el-col :span="15">
              <el-button
                type="primary"
                icon="el-icon-search"
                size="medium"
                :loading="loadingFilter"
                @click="filterTable"
                >Buscar</el-button
              >
              <el-button
                type="warning"
                icon="el-icon-download"
                size="medium"
                @click="modalOpen = true"
                >Descargar</el-button
              >
            </el-col>
          </el-row>
          <el-table
            :data="tableData"
            v-loading="loadingTable"
            style="width: 100%; margin-top: 20px"
            height="530"
          >
            <el-table-column
              label="Documento"
              prop="documento"
              sortable
            ></el-table-column>
            <el-table-column
              label="Nombre"
              prop="nombre"
              sortable
            ></el-table-column>
            <el-table-column
              label="Telefono"
              prop="telefono"
              sortable
            ></el-table-column>
            <el-table-column label="Tipo" width="250px">
              <template slot-scope="scope">
                <el-tag
                  v-if="scope.row.cliente == 1"
                  size="small"
                  type="success"
                  style="margin-right: 5px"
                  >Cliente</el-tag
                >
                <el-tag
                  v-if="scope.row.vendedor == 1"
                  size="small"
                  type="warning"
                  style="margin-right: 5px"
                  >Vendedor</el-tag
                >
                <el-tag v-if="scope.row.proveedor == 1" size="small"
                  >Proveedor</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column width="100px" align="right">
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Editar"
                  placement="top"
                >
                  <el-button
                    size="mini"
                    type="warning"
                    @click="handleEdit(scope.$index, scope.row)"
                    icon="el-icon-edit"
                    circle
                  ></el-button>
                </el-tooltip>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Eliminar"
                  placement="top"
                >
                  <el-button
                    :disabled="scope.row.usado != 0"
                    size="mini"
                    type="danger"
                    @click="handleDelete(scope.$index, scope.row)"
                    icon="el-icon-delete"
                    circle
                  ></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div
            v-if="tableData.length > 0"
            class="pagination-box animated fadeInRight"
            style="text-align: end; padding-top: 10px"
          >
            <el-pagination
              layout="total, prev, pager, next"
              :page-size="pageSize"
              :total="total_rows"
              @current-change="handleCurrentPage"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>

      <modal-third
        :modalOpen="modalOpen"
        @close="modalOpen = false"
      ></modal-third>
    </div>
  </div>
</template>

<script>
import ModalThird from "@/views/apps/thirds/ModalThirds.vue";
import { getUser } from "@/utils/auth";
import { getAll, store, update, deletePerson } from "@/api/person";
export default {
  name: "Third",
  components: {
    ModalThird,
  },
  data() {
    return {
      user: getUser(),
      loading: false,
      loadingTable: false,
      loadingFilter: false,
      modalOpen: false,
      edit: false,
      sellerType: false,
      tableData: [],
      docuemnt: "",
      form: {
        documento: "",
        nombre: "",
        direccion: "",
        ciudad: "",
        email: "",
        telefono: "",
        nacimiento: "",
        cliente: "",
        vendedor: "",
        proveedor: "",
        cargo: "",
        branchId: ""
      },
      //
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    handleCurrentPage(val) {
      this.getAll(val);
    },
    filterTable() {
      this.loadingFilter = true;
      this.getAll();
    },
    getAll(current) {
      this.loadingTable = true;
      if (typeof current == "undefined") {
        if (this.document != null) {
          this.currentPage = 1;
        }
      } else {
        this.currentPage = current;
      }
      getAll(this.pageSize, this.currentPage, this.docuemnt)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
          this.loadingFilter = false;
        })
        .catch(function (error) {
          this.loadingTable = false;
          this.loadingFilter = false;
          console.log(error);
        });
    },
    submitForm(formName) {
      this.loadingTable = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.edit) {
            this.updateForm();
          } else {
            this.saveForm();
          }
        } else {
          this.loadingTable = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      store(this.form)
        .then(({ data }) => {
          this.loadingTable = false;
          if (data.code === 200) {
            this.getAll();
            this.resetForm("form");
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    updateForm() {
      update(this.form)
        .then(({ data }) => {
          if (data.code === 200) {
            this.getAll();
            this.resetForm("form");
            this.loadingTable = false;
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.form.id = "";
      this.form.cliente = false;
      this.form.vendedor = false;
      this.form.proveedor = false;
      this.edit = false;
      this.loading = false;
      this.loadingTable = false;
      this.sellerType = false;
    },
    handleEdit(index, row) {
      this.edit = true;
      this.form.id = row.id;
      this.form.documento = row.documento;
      this.form.nombre = row.nombre;
      this.form.direccion = row.direccion;
      this.form.ciudad = row.ciudad;
      this.form.email = row.email;
      this.form.telefono = row.telefono;
      this.form.nacimiento = row.nacimiento;
      if (row.cliente == 1) {
        this.form.cliente = true;
      } else {
        this.form.cliente = false;
      }
      if (row.vendedor == 1) {
        this.form.vendedor = true;
        this.sellerType = true;
      } else {
        this.form.vendedor = false;
        this.sellerType = false;
      }
      if (row.proveedor == 1) {
        this.form.proveedor = true;
      } else {
        this.form.proveedor = false;
      }
      this.form.cargo = row.cargo;
    },
    handleDelete(index, row) {
      this.loadingTable = true;
      deletePerson({ id: row.id })
        .then(({ data }) => {
          if (data.code === 200) {
            this.getAll();
            this.resetForm("form");
          } else {
            this.loadingTable = false;
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loadingTable = false;
          console.log("Err: ", error);
        });
    },
  },
  created() {
    let me = this
    this.getAll();
    setTimeout(() => {
      me.form.branchId = me.user.sucursal_id
    }, 2000);
  },
};
</script>
<style lang="scss" scope>
.form {
  margin-top: 15px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

