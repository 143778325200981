<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-form :model="form" ref="form" status-icon label-width="70px" size="medium" class="form" :inline="true">
            <el-form-item prop="product">
              <el-autocomplete v-model="form.product" :fetch-suggestions="querySearchAsync" placeholder="Buscar producto"
                @select="handleSelect" @clear="clearSelect" clearable>
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
                <template slot-scope="{ item }">
                  <div class style="line-height: 1; padding: 8px 0 8px">
                    <i class="mdi mdi-cart-outline"></i>
                    {{ item.descripcion }}
                    <br />
                    <small>
                      <i class="mdi mdi-barcode"></i>
                      {{ item.codigo_interno }}
                    </small>
                    <small>
                      <i class="mdi mdi-tag-text-outline"></i>
                      {{ item.category.descripcion }}
                    </small>
                  </div>
                </template>
              </el-autocomplete>
            </el-form-item>
            <el-form-item prop="dateRange">
              <el-date-picker v-model="form.dateRange" type="daterange" align="right" unlink-panels range-separator="a"
                start-placeholder="Fecha inicio" end-placeholder="Fecha fin" value-format="yyyy-MM-dd" :picker-options="pickerOptions">
              </el-date-picker>
            </el-form-item>
            <el-form-item  prop="payment">
              <el-select
                  ref="payment_method"
                  v-model="form.payment"
                  multiple 
                  clearable
                  filterable
                  placeholder="Formas de pago"
                  class="payment-select"
                  value-key="id"
                  @change="setPaymentMethod"
                >
                  <el-option
                    v-for="item in payments"
                    :key="item.id"
                    :label="item.descripcion"
                    :value="item.id"
                  ></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item  prop="category_id">
              <el-select
                v-model="form.category_id"
                filterable
                placeholder="Selecciona una categoría"
                @clear="form.category_id = ''"
                clearable
              >
                <el-option
                  v-for="item in categories"
                  :key="item.id"
                  :label="item.descripcion"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="submitForm('form')" :loading="loading"
                icon="el-icon-search">Filtrar</el-button>
              <el-button type="success" @click="dowloadSales" :loading="loadingDownload" :disabled="!showBtnDownload"
                icon="el-icon-download">Descargar Excel</el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <list :get="getData" :form="form" @complete="complete" @table-data="tableData"></list>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { dowloadSales } from "@/api/reports/sales";
import { searchProductByData } from "@/api/product";
import { actualDate } from "@/utils/date";
import { getPayments } from "@/api/payment";
import { getUser } from "@/utils/auth";
import List from "./List.vue";
export default {
  name: "Sales",
  components: {
    List,
  },
  data() {
    return {
      user: getUser(),
      loading: false,
      loadingTable: false,
      loadingDownload: false,
      getData: false,
      modalOpen: false,
      showBtnDownload: false,
      payments: [],
      form: {
        product: "",
        product_id: "",
        dateRange: '',
        payment: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: '-Ultima semana',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '-Ultimo mes',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '-Ultimos 3 meses',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    };
  },
  methods: {
    getPaymentsData() {
      getPayments(this.user.sucursal_id)
        .then(({ data }) => {
          this.payments = data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dowloadSales() {
      let me = this;
      me.loadingDownload = true;
      dowloadSales({
        product_id: this.form.product_id,
        dateRange: this.form.dateRange,
        payments: this.form.payment,
      })
        .then(({ data }) => {
          var dateString = actualDate().substring(0, 10);
          const url = URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Reporte de ventas " + dateString);
          document.body.appendChild(link);
          link.click();
          me.loadingDownload = false;
        })
        .catch(function (error) {
          me.loadingDownload = false;
          console.log(error);
          me.$message({
            type: "error",
            message: "Error! " + error.message,
          });
        });
    },
    querySearchAsync(queryString, cb) {
      if (queryString && queryString.length > 2) {
        searchProductByData({ data: queryString })
          .then(({ data }) => {
            cb(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    handleSelect(item) {
      console.log(item);
      this.form.product = item.descripcion;
      this.form.product_id = item.id;
    },
    clearSelect() {
      this.form.product = "";
      this.form.product_id = "";
    },
    submitForm() {
      this.getData = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.loadingTable = false;
    },
    complete(complete) {
      this.getData = false;
    },
    tableData(data) {
      console.log(data);
      if (data.length > 0) {
        this.showBtnDownload = true
      } else {
        this.showBtnDownload = false;
      }
    },
  },
  created() {
    this.getPaymentsData()
  },
};
</script>