<template>
  <div>
    <el-table
      :data="tableData"
      v-loading="loadingTable"
      height="400"
      style="width: 100%"
    >
      <div slot="empty">
        <el-empty description="No hay datos"></el-empty>
      </div>
      <el-table-column prop="product.codigo_interno" label="Código" width="200">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-barcode"></i>
            {{ (scope.row.product.codigo_interno !== '') ? scope.row.product.codigo_interno : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.descripcion" label="Producto">
        <template slot-scope="scope">
          <div>
            <i class="mdi mdi-cart-outline"></i>
            {{ scope.row.product.descripcion }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="product.category.descripcion" label="Categoria">
        <template slot-scope="scope">
          <div>
            <i class="el-icon-collection-tag"></i>
            {{ scope.row.product.category.descripcion }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="inventory" label="Inventario actual" width="150">
        <template slot-scope="scope">
          <div>
            {{ scope.row.inventory }}
            <el-tooltip
              class="item"
              effect="dark"
              content="Esta en stock minimo!"
              placement="top"
            >
              <el-tag
                type="warning"
                size="mini"
                v-if="scope.row.inventory > 0 && scope.row.inventory <= scope.row.product.stock_minimo"
                style="float: right;"
                >Bajo!</el-tag
              >
            </el-tooltip>
            <el-tooltip

              class="item"
              effect="dark"
              content="Producto sin stock!"
              placement="top"
            >
              <el-tag
                type="danger"
                size="mini"
                v-if="scope.row.inventory == 0"
                style="float: right;"
                >Sin stock!</el-tag
              >
            </el-tooltip>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div
      v-if="tableData.length > 0"
      class="pagination-box animated fadeInRight"
      style="text-align: end; padding-top: 10px"
    >
      <el-pagination
        layout="total, prev, pager, next"
        :page-size="pageSize"
        :total="total_rows"
        @current-change="handleCurrentPage"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import { getInventory } from "@/api/document";
export default {
  name: "ListInventory",
  props: ["get", "form"],
  watch: {
    get: function (val) {
      if (val) {
        console.log("form:", this.form);
        this.getAll();
      }
    },
  },
  data() {
    return {
      loadingTable: false,
      tableData: [],
      //
      pageSize: 50,
      currentPage: 1,
      total_rows: 0,
    };
  },
  methods: {
    getAll() {
      this.loadingTable = true;
      var product_id =
        this.form.product_id != "" ? this.form.product_id : "null";
      var category_id =
        this.form.category_id != "" ? this.form.category_id : "null";
      var type_inventory =
        this.form.type_inventory != "" ? this.form.type_inventory : "null";
      getInventory(this.pageSize, this.currentPage, product_id, category_id, type_inventory)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
          this.$emit("complete", true);
          this.$emit("table-data", this.tableData);
        })
        .catch(function (error) {
          console.log(error);
          this.$emit("complete", true);
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val;
      this.getAll();
    },
  },
  created() {
    // this.getAll();
  },
};
</script>

<style lang="scss" scoped>
.btn-input {
  padding: 3px;
}
</style>