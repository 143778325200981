<template>
  <div class="scrollable only-y ">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-10">
      <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="7" :lg="8" :xl="9">
          <el-select v-model="branch" size="medium" filterable value-key="id" placeholder="Seleccione la sucursal"
            clearable @clear="
              branch = null;
            getInventoryData();
            ">
            <el-option v-for="item in stores" :key="item.id" :label="item.razon_social" :value="item.id"></el-option>
          </el-select>
        </el-col>
        <el-col :xs="12" :sm="12" :md="7" :lg="8" :xl="9">
          <el-input placeholder="Producto" size="medium" v-model="product" clearable @clear="
            product = null;
          getInventoryData();
          ">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </el-col>
        <el-col :xs="24" :sm="24" :md="10" :lg="8" :xl="6" class="flex-btn">
          <el-button type="primary" size="medium" icon="el-icon-search" :loading="loadingFilter" @click="filterTable">
            Buscar
          </el-button>
          <el-button type="success" size="medium" icon="el-icon-download" :loading="loadingDownload" @click="download">
            Descargar
          </el-button>
        </el-col>
      </el-row>


      <el-row :gutter="50">
        <el-col :span="24">
          <el-table :data="tableData" v-loading="loadingTable" style="width: 100%" height="400">
            <el-empty slot="empty" description="No hay datos"></el-empty>
            <el-table-column prop="document" label="Documento" width="120">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.document.type.prefijo }} - {{ scope.row.document.consecutivo }}
                </div>
                <div><i class="el-icon-date"></i> {{ scope.row.document.fecha }}</div>
              </template>
            </el-table-column>
            <!-- <el-table-column prop="document.fecha" label="Fecha" min-width="80">
            </el-table-column> -->
            <el-table-column prop="product.codigo_interno" label="Código" min-width="90">
            </el-table-column>
            <el-table-column prop="product.descripcion" label="Producto" min-width="180">
            </el-table-column>
            <el-table-column prop="product.costo" label="Costo">
              <template slot-scope="scope">
                <div>$ {{ scope.row.product.costo }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="product.precio_venta" label="P. venta">
              <template slot-scope="scope">
                <div>$ {{ scope.row.product.precio_venta }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="branch" label="Bodega" min-width="80">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.branch.razon_social }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="cant_final" width="90" :label="'Cantidad (inv: ' + inventory + ')'">
              <template slot-scope="scope">
                <el-tag size="small" effect="dark" :type="(scope.row.cant_final > 0) ? 'success' : 'danger'">{{
                  scope.row.cant_final
                }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
          <div v-if="tableData.length > 0" class="pagination-box" style="text-align: end; padding-top: 10px">
            <el-pagination layout="total, prev, pager, next" :page-size="pageSize" :total="total_rows"
              @current-change="handleCurrentPage"></el-pagination>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getKardex, dowloadInventory } from "@/api/reports/kardex.js";
import { getAll as getStores } from "@/api/store";
export default {
  name: "Inventory",
  data() {
    return {
      loadingTable: false,
      loadingFilter: false,
      loadingDownload: false,
      tableData: [],
      stores: [],
      branch: null,
      product: null,
      dateRange: '',
      pageSize: 10,
      currentPage: 1,
      total_rows: 0,
      inventory: 0,
    };
  },
  methods: {
    async download() {
      let me = this;
      // let data = await testD()
      // console.log(data);
      me.loadingDownload = true;
      dowloadInventory({ branch: me.branch, product: me.product })
        .then(({ data }) => {
          // var dateString = "";
          // if (typeof this.dateRange == "string") {
          //   dateString = actualDate().substring(0, 10);
          // } else {
          //   dateString = this.dateRange.toString();
          // }
          const url = URL.createObjectURL(
            new Blob([data], {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Kardex");
          document.body.appendChild(link);
          link.click();
          me.loadingDownload = false;
        })
        .catch(function (error) {
          me.loadingDownload = false;
          console.log(error);
          this.$message({
            type: "error",
            message: "Error! ",
          });
        });
    },
    filterTable() {
      this.loadingFilter = true;
      this.getKardexData();
    },
    getKardexData() {
      this.loadingTable = true;
      // if (typeof current == "undefined") {
      //   if (this.branch != null) {
      //     this.currentPage = 1;
      //   }
      // } else {
      //   this.currentPage = current;
      // }
      getKardex(this.pageSize, this.currentPage, this.branch, this.product)
        .then(({ data }) => {
          this.tableData = data.data;
          this.total_rows = data.total;
          this.loadingTable = false;
          this.loadingFilter = false;
          data.data.forEach(element => {
            this.inventory += parseInt(element.cant_final);
          });
        })
        .catch(function (error) {
          this.loadingTable = false;
          this.loadingFilter = false;
          console.log(error);
        });
    },
    handleCurrentPage(val) {
      this.currentPage = val
      this.getKardexData();
    },
    getStores() {
      getStores()
        .then(({ data }) => {
          this.stores = data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  created() {
    // this.getKardexData();
  },
  mounted() {
    this.getStores();
  },
};
</script>
<style lang="scss" scope>
.flex-btn {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.form {
  margin-top: 15px;
}

.el-date-editor {
  width: 100% !important;
}
</style>
