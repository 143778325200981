
const document = {
  state:{
    document:{},
    detail:{},
    client:{},
    payment:{},
    printer_default:'',
    printerConnect:false,
    printerReConnect:false,
    btn_new:false,
    btn_saved:false,
    id_document:'',
    document_view: {},
    detail_view: {},
    getDailySale:false,
  },
  mutations:{
    SET_PRINTER_RECONNECT: (state, connect) => {
      state.printerReConnect = connect
    },
    SET_PRINTER_CONNECT: (state, connect) => {
      state.printerConnect = connect
    },
    SET_ID_DOCUMENT: (state, id) => {
      state.id_document = id
    },
    SET_DOCUMENT: (state, document) => {
      state.document = document
    },
    SET_DETAIL: (state, detail) => {
      state.detail = detail
    },
    SET_CLIENT: (state, client) => {
      state.client = client
    },
    SET_PRINTER: (state, printer_default) => {
      state.printer_default = printer_default
    },
    SET_PAYMENT: (state, payment) => {
      state.payment = payment
    },
    SET_DOCUMENT_VIEW: (state, data) => {
      state.document_view = data
    },
    SET_DETAIL_VIEW: (state, data) => {
      state.detail_view = data
    },
    SET_BTN_NEW: (state, data) => {
      state.btn_new = data
    },
    SET_BTN_SAVED: (state, data) => {
      state.btn_saved = data
    },
    SET_DAILY_SALE: (state, data) => {
      state.getDailySale = data
    },
  },
  actions:{
    
  }
}

export default document