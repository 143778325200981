<template>
  <div class="scrollable only-y">
    <div class="page-header">
      <h1><i :class="'mdi mdi-' + $route.meta.icon"></i> {{ $route.name }}</h1>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <i class="mdi mdi-home-outline"></i>
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ $route.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-base card-shadow--medium p-30">
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <modal-list-document :typeDocument="form.type_id" :transaction="2"></modal-list-document>

          <el-form
            :model="form"
            :rules="rules"
            ref="form"
            status-icon
            label-width="70px"
            size="medium"
            class="form"
            :inline="true"
          >
            <el-form-item label="Cantidad" prop="quantity">
              <el-input-number
                v-model="form.quantity"
                :min="1"
                :max="100000"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Código" prop="code">
              <el-input
                type="text"
                v-model="form.code"
                clearable
                autocomplete="off"
                @keyup.enter.native="submitForm"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('form')"
                :loading="loading"
                icon="el-icon-plus"
                >Agregar</el-button
              >
              <el-button type="success" icon="el-icon-check" @click="saveLoad"
                >Guardar Ajuste de Salida</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <el-row :gutter="50">
        <el-col :span="24" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <list :get="getData" @complete="complete"></list>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import ModalListDocument from '../ModalListDocument.vue';
import ModalWithoutProducts from '../ModalWithoutProducts.vue';
import { loadInventory, saveLoadInventory } from "@/api/document";
import List from "./List.vue";
export default {
  name: "OutputSetting",
  components: {
    List,
    ModalWithoutProducts,
    ModalListDocument,
  },
  data() {
    return {
      loading: false,
      loadingTable: false,
      getData: false,
      modalOpen: false,
      form: {
        code: "",
        quantity: 1,
        type_id: 8, // ajuste de salida
      },
      rules: {
        code: [
          {
            required: true,
            message: "Ingresa una código",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    saveLoad() {
      let me = this;
      this.$msgbox({
        title: "Atención",
        message:
          "Se ajustarán los datos en el inventario de la tienda. Desea continuar?",
        showCancelButton: true,
        confirmButtonText: "Si, ajustar salida",
        cancelButtonText: "No, cancelar",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "Cargando datos...";
            saveLoadInventory({type_id: this.form.type_id})
              .then(({ data }) => {
                if (data.code === 200) {
                  me.getData = true;
                  me.resetForm("form");
                  me.$message({
                    message: "Se ha ajustado el inventario en la tienda",
                    type: "success",
                  });
                } else {
                  me.$message.error("Error: " + data.error);
                }
                me.loadingTable = false;
                done();
                instance.confirmButtonLoading = false;
              })
              .catch(function (error) {
                instance.confirmButtonLoading = false;
                me.loadingTable = false;
                me.$message.error("Error! " + error.response.data.message);
                console.log("Err: ", error);
              });
          } else {
            done();
          }
        },
      });
    },
    submitForm() {
      let me = this;
      me.loading = true;
      loadInventory(me.form)
        .then(({ data }) => {
          if (data.code === 200) {
            me.getData = true;
            me.resetForm("form");
            me.$message({
              message: "Producto cargado!",
              type: "success",
            });
          } else {
            me.$message.error("Error: " + data.error);
          }
          me.loading = false;
          me.loadingTable = false;
        })
        .catch(function (error) {
          me.loading = false;
          me.loadingTable = false;
          me.$message.error("Error! " + error.response.data.message);
          console.log("Err: ", error);
        });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.loading = false;
      this.loadingTable = false;
    },
    complete(complete) {
      this.getData = false;
    },
  },
};
</script>