import request from "@/utils/request";

const model = "payment_provider";

export function getAll(page_size, currentPage, data) {
  return request({
    url: model + "/getAll/" + page_size + "?page=" + currentPage,
    method: "post",
    data,
  });
}

export function store(data) {
  return request({
    url: model + "/store",
    method: "post",
    data,
  });
}

export function storeDetail(data) {
  return request({
    url: model + "/storeDetail",
    method: "post",
    data,
  });
}

export function update(data) {
  return request({
    url: model + "/update",
    method: "put",
    data,
  });
}

export function deleteData(id) {
  return request({
    url: model + "/destroy/" + id,
    method: "delete",
  });
}

export function getById(id) {
  return request({
    url: model + "/getById/" + id,
    method: "get",
  });
}

export function getPaymentTypes() {
  return request({
    url: model + "/getPaymentTypes",
    method: "get",
  });
}
