//pages
import Index from '@/views/apps/reports/Index.vue'
import Inventory from '@/views/apps/reports/Inventory/Index.vue'
import Sales from '@/views/apps/reports/Sales/Index.vue'
import Kardex from '@/views/apps/reports/Kardex.vue'
import layouts from '@/layout'

export default {
  path: 'inputSetting',
  name: 'Informes',
  component: Index,
  redirect: { path: '/inputSetting', name: 'inputSetting' },
  icon: 'file-document-outline',
  meta: {
		auth: true,
		nav: true,
		roles: ['admin']
	},
  children: [
    {
      path: '/inventory',
      alias: '/inventory',
      name: 'Inventario',
      component: Inventory,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Inventario'],
        roles: ['admin'],
        icon: 'file-table-outline'
      }
    },
    {
      path: '/sales',
      alias: '/sales',
      name: 'Ventas',
      component: Sales,
      meta: {
        auth: true,
        nav: true,
        layout: layouts.navLeft,
        searchable: true,
        tags: ['Ventas'],
        roles: ['admin'],
        icon: 'currency-usd-circle-outline'
      }
    },
    {
			path: '/kardex',
			name: 'Kardex',
			component: Kardex,
			meta: {
				nav: true,
				layout: layouts.navLeft,
				roles: ['admin', 'deposit'],
				icon: 'cash-register',
			}
		}
  ]
}