<template>
  <el-dialog title="Datos de registro" width="50%" :visible.sync="openModal" :close-on-click-modal="false"
    :close-on-press-escape="false" :before-close="closeModal" :destroy-on-close="true" top="5vh">

    <el-row :gutter="12">
      <el-col :span="8">
        <el-card shadow="never">
          <div class="widget">
            <div class="text-uppercase text-right flex">
              <div class="icon-box ph-15 accent-text"><i class="mdi mdi-file-document-outline"></i></div>
              <div class="box grow content-box">
                <p class="m-0 fw-900">Credito</p>
                <h3 class="m-0">$ {{ formatNumber(credit) }}</h3>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never">
          <div class="widget">
            <div class="text-uppercase text-right flex">
              <div class="icon-box ph-15 success-text"><i class="mdi mdi-file-document-outline"></i></div>
              <div class="box grow content-box">
                <p class="m-0 fw-900">Pago actual</p>
                <h3 class="m-0 success-text">$ {{ formatNumber(total_pay) }}</h3>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="8">
        <el-card shadow="never">
          <div class="widget">
            <div class="text-uppercase text-right flex">
              <div class="icon-box ph-15 danger-text"><i class="mdi mdi-file-document-outline"></i></div>
              <div class="box grow content-box">
                <p class="m-0 fw-900">Debe</p>
                <h3 class="m-0 danger-text">$ {{ formatNumber(difference) }}</h3>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-form :model="form" :rules="rules" ref="form" status-icon label-position="top" label-width="150px" size="medium"
      class="form" v-loading="loadingForm">
      <el-row :gutter="20">
        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Fecha del abono" prop="credit_date">
            <label slot="label"><i class="el-icon-date"></i> Fecha del abono</label>
            <el-input type="text" v-model="form.credit_date" autocomplete="off" placeholder="Fecha" clearable
              :disabled="true"></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Número de factura" prop="invoice">
            <label slot="label"><i class="el-icon-document-checked"></i> Número de factura</label>
            <el-input type="text" v-model="form.invoice" autocomplete="off" placeholder="# Factura" clearable
              :disabled="true">
            </el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12" :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <el-form-item label="Valor del abono" prop="credit_value">
            <label slot="label">
              <i class="el-icon-money"></i> Valor del abono</label>
            <el-input type="text" v-model="form.credit_value" autocomplete="off" placeholder="Valor" ref="credit_value"
              clearable></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="openModalList" icon="el-icon-files" type="primary">Listar abonos</el-button>
      <!-- save and edit btn  -->
      <el-button size="small" type="success" @click="submitForm('form')" :loading="loading" icon="el-icon-check"
      v-if="!edit">Guardar</el-button>
      <el-button size="small" type="warning" @click="submitForm('form')" :loading="loading" icon="el-icon-edit" v-else>
        Actualizar</el-button>
        <el-button size="small" @click="closeModal" icon="el-icon-close">Cancelar</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { formatInt, formatNumber } from "@/utils/number";
import { actualDate } from "@/utils/date";
import {
  store,
  update,
} from "@/api/amortization";
export default {
  name: 'ModalCreateAmortization',
  props: ['openModal', 'dataForm', 'edit'],
  watch: {
    dataForm: {
      deep: true,
      handler(val, oldVal) {
        if (Object.keys(val).length !== 0) {
          this.handleEdit(val);
        } else {
          this.resetForm('form')
        }
      },
    },
  },
  data() {
    var validateIntCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Ingresa el número factura"));
      } else {
        if (this.edit) {
          callback();
        } else {
          callback();
          // validateCodeExist(value)
          //   .then(({ data }) => {
          //     console.log("datos", data);
          //     if (data) {
          //       callback(new Error("El código interno ingresado, ya existe"));
          //     } else {
          //       callback();
          //     }
          //   })
          //   .catch(function (error) {
          //     console.log(error);
          //     callback(new Error("Ocurrio un error al validar el código"));
          //   });
        }
      }
    };
    return {
      form: {
        document_id: "",
        payment_detail_id: "",
        invoice: "",
        credit_date: "",
        credit_value: "",
      },
      difference: 0,
      total_pay: 0,
      credit: 0,
      rules: {
        invoice: [
          {
            validator: validateIntCode,
            trigger: "blur",
          },
        ],
        credit_date: [
          {
            required: true,
            message: "Ingresa la fecha",
            trigger: "blur",
          },
        ],
        credit_value: [
          {
            required: true,
            message: "Ingresa el valor del abono",
            trigger: "blur",
          },
        ],
      },
      loadingForm: false,
      loading: false,
    }
  },
  methods: {
    openModalList(){
      this.$emit('closeModal', {'open':true,'data':this.dataForm});
    },
    handleEdit(row) {
      // console.log('edit', row);
      let me = this
      if(this.edit){
        this.form.id = row.id;
      }
      this.form.document_id = parseInt(row.documento_id);
      this.form.payment_detail_id = parseInt(row.id);
      this.form.invoice = parseFloat(row.document.consecutivo);
      this.form.credit_date = (row.credit_date) ? row.credit_date : actualDate().slice(0, -9);
      this.form.credit_value = (row.credit_value) ? row.credit_value : '';
      this.difference = parseFloat(row.difference);
      this.total_pay = parseFloat(row.total_pay);
      this.credit = parseFloat(row.valor);
      this.$nextTick(() => this.$refs.credit_value.focus());
    },
    resetForm(formName) {
      // this.$refs[formName].resetFields();
      this.loading = false;
    },
    submitForm(formName) {
      this.loading = true;
      this.loadingForm = true;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.edit) {
            this.updateForm();
          } else {
            this.saveForm();
          }
        } else {
          this.loadingForm = false;
          this.loading = false;
          console.log("error submit!!");
          return false;
        }
      });
    },
    saveForm() {
      let me = this;
      store(this.form)
        .then(({ data }) => {
          if (data.code === 200) {
            this.$message({
              type: "success",
              message: "Abono registrado!",
            });
            this.closeModal(true);
          } else {
            this.$message.error("Error! " + data.error.errorInfo[2]);
          }
        })
        .catch(function (error) {
          this.loading = false;
          console.log("Err: ", error);
        });
    },
    updateForm() {
      let me = this;
      update(this.form)
        .then(({ data }) => {
          if (data.code === 200) {
            this.closeModal(true);
          } else {
            this.$message.error("Error! " + data.error);
          }
        })
        .catch(function (error) {
          console.log("Err: ", error);
          me.loading = false;
        });
    },
    deleteData(index, row) {
      let me = this
      console.log('eliminar', row);
    },

    formatNumber(number, round) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(formatInt(number));
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      return num;
    },
    closeModal(refreshTable) {
      this.resetForm('form');
      this.loadingForm = false;
      this.loading = false;
      let dataEmit = null
      if (refreshTable === true) {
        dataEmit = { refreshTable: true };
      }
      this.$emit('closeModal', dataEmit);
    }
  },
  created() {
    //
  },
}
</script>

<style lang="scss" scope>
.content-box {
  display: grid;
  place-items: center;
}

.icon-box {
  font-size: 50px;
}

.el-autocomplete {
  width: 100%;
}</style>