<template>
  <div class="page-ecommerce-products flex">
    <PersonAndPay :sidebarOpen="sidebarOpen" :value_invoice="total" :resetFields="resetFields"
      :printerDefault="printer_default" @close="sidebarOpen = false" @save="ValidateDocument"></PersonAndPay>
    <div class="list scrollable box grow">
      <div class="toggle-filter-box">
        <button @click="sidebarOpen = !sidebarOpen">
          <span v-if="!sidebarOpen">Ver datos de pago</span>
          <span v-if="sidebarOpen">Cerrar datos de pago</span>
        </button>
      </div>
      <div class="title-card card-form card-base card-outline card-shadow--small">
        <div class="invoice_title">
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="Facturas Pausadas"
            placement="top"
          >
            <el-badge v-config="'paused_bills'" :value="pausedBills" class="paused-bills" :hidden="(pausedBills > 0) ? false : true">
              <el-button
                size="small"
                class="paused-bills-btn"
                icon="el-icon-video-pause"
                :type="(pausedBills > 0) ? 'info' : ''"
                round
              ></el-button>
            </el-badge>
          </el-tooltip> -->
          <span class="quantity">
            <i class="mdi mdi-cart-arrow-down"></i>
            {{ quantity_total }}
          </span>
          <el-divider direction="vertical"></el-divider>
          <span class="value">$ {{ this.formatNumber(total) }}</span>
        </div>
        <span class="store_title">
          <i class="mdi mdi-store"></i>
          {{ user.branch.razon_social }}
        </span>
        <br />
        <span class="cash_register_title">
          <i class="mdi mdi-cash-register"></i>
          {{ dataPos.computer.name }} ({{ dataPos.computer.code }})
          <el-tag type="success" size="small"> Venta diaria: <strong class="dailySaleValue">${{ dailySale
          }}</strong></el-tag>
        </span>
      </div>
      <div class="widget">
        <!-- <el-button @click="printDocument()">Print</el-button> -->
        <div class="title">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-input ref="product" class="search-product" placeholder="Buscar Producto..." v-model="code_product"
                @keyup.native.enter="search_product" size="small" clearable>
                <i slot="prefix" class="el-input__icon el-icon-goods"></i>
                <el-button slot="append" icon="el-icon-search" @click="modalProductOpen = true"></el-button>
              </el-input>
            </el-col>
            <el-col :span="12">
              <!-- <el-button
                type="danger"
                size="mini"
                round
                class="btn-plastic-bag"
                icon="el-icon-sell"
                @click="search_product(281118)"
                >Facturar Bolsa Plastica</el-button
              > -->
            </el-col>
          </el-row>
        </div>
        <div class="content border-bottom">
          <el-row>
            <el-col :span="23" style="background: #fbfbfb; width: 99%">
              <vue-scroll class="scroller detail">
                <div style="height: 320px">
                  <el-table :show-header="false" :data="tableData" v-loading="loading">
                    <div slot="empty">
                      <el-empty description="No hay datos" :image-size="100"></el-empty>
                    </div>
                    <el-table-column type="expand" width="30">
                      <template slot-scope="props">
                        <el-row class="wfull detail-more" :gutter="10">
                          <el-col :xs="12" :sm="8" :md="4" :lg="4" :xl="4" class="ph-10">
                            <label for>Descuento</label>
                            <el-input placeholder="$0.00" size="small" v-model="props.row.discount"
                              @change="calculateTotals(props)" @clear="calculateTotals(props)" clearable></el-input>
                          </el-col>
                          <!-- <el-col
                            :xs="12"
                            :sm="12"
                            :md="12"
                            :lg="8"
                            :xl="5"
                            class="ph-10"
                          >
                            <label for
                              >Descuento
                              {{ props.row.discount_percentage }}%</label
                            >
                            <el-input
                              placeholder="0%"
                              size="small"
                              v-model="props.row.discount"
                              @change="calculateTotals(props)"
                              @clear="calculateTotals(props)"
                              clearable
                              disabled
                            >
                              <el-tooltip
                                slot="prepend"
                                class="item"
                                effect="dark"
                                content="Eliminar descuento"
                                placement="top"
                              >
                                <el-button icon="el-icon-remove-outline" @click="deleteDiscount(props.$index, props)"></el-button>
                              </el-tooltip>
                              <el-tooltip
                                slot="append"
                                class="item"
                                effect="dark"
                                content="Aplicar descuento"
                                placement="top"
                              >
                                <el-button icon="el-icon-circle-check" @click="applyDiscount(props.$index, props)"></el-button>
                              </el-tooltip>
                            </el-input>
                          </el-col> -->
                          <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="5" class="ph-10">
                            <label for>IVA</label>
                            <div class="txt-iva">
                              $ {{ formatNumber(props.row.iva, true) }}
                            </div>
                          </el-col>
                        </el-row>
                      </template>
                    </el-table-column>
                    <el-table-column label width="50">
                      <template slot-scope="props">
                        <el-tooltip class="item" effect="dark" content="Eliminar" placement="top">
                          <el-button type="danger" icon="el-icon-delete" size="mini" circle class="btn-delete-circle"
                            @click="deleteRow(props.$index, props.row)"></el-button>
                        </el-tooltip>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label width="50" v-if="innerWidth > 678">
                      <template slot-scope="props">
                        <div class="image-input">
                          <el-image v-if="props.row.image" :src="urlImage + props.row.image" class="avatar" alt="avatar"
                            :preview-src-list="[urlImage + props.row.image]"></el-image>
                          <el-image v-else src="/static/images/products/no-image.png" class="avatar" alt="avatar"
                            :preview-src-list="[
                              '/static/images/products/no-image.png',
                            ]"></el-image>
                        </div>
                      </template>
                    </el-table-column> -->
                    <el-table-column label="Nombre" prop="name" width="250"></el-table-column>
                    <el-table-column label="Cantidad" min-width="110">
                      <template slot-scope="props">
                        <div class="col-input">
                          <el-input-number size="small" :min="1" :max="props.row.balance" v-model="props.row.quantity"
                            @change="calculateTotals(props)"></el-input-number>
                        </div>
                      </template>
                    </el-table-column>
                    <!-- <el-table-column label="Precio" :width="innerWidth < 678 ? 0 : ''">
                      <template slot-scope="props" v-if="innerWidth > 678">
                        <div class="number-input">
                          <label class="total_detail">$ {{ props.row.price }}</label>
                        </div>
                      </template>
                    </el-table-column> -->
                    <el-table-column label="Total" :width="innerWidth < 678 ? 0 : ''">
                      <template slot-scope="props">
                        <div class="number-input">
                          <label class="total_detail">$ {{ props.row.total }}</label>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="Total IVA">
                      <template slot-scope="props">
                        <div class="number-input">
                          <label class="total_detail">$ {{
                            formatNumber(props.row.subtotal + props.row.iva, true, true)
                          }}</label>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </vue-scroll>
            </el-col>
          </el-row>
        </div>
        <div class="content">
          <el-row :gutter="10">
            <el-col :span="12" style>
              <div>
                <el-button type="primary" icon="el-icon-edit" round size="mini" class="btn-seller"
                  @click="modalOpen = true">Vendedor (F4)</el-button>

                <div style="margin-top: 10px; margin-bottom: 10px" v-if="dataPos.sellers.length > 0">
                  <el-radio-group v-model="sellerSelected" size="small" @change="setSellerWithName" fill="#67C23A">
                    <template v-for="item in dataPos.sellers">
                      <el-radio-button :label="item.seller.nombre" v-bind:key="item.id_vendedor"></el-radio-button>
                    </template>
                  </el-radio-group>
                </div>

                <div class="seller">
                  <i class="el-icon-postcard"></i>
                  {{ seller.nit }}
                </div>
                <div class="seller">
                  <i class="el-icon-user"></i>
                  {{ seller.name }}
                </div>
                <div class="seller">
                  <i class="el-icon-location-information"></i>
                  {{ seller.city }}
                </div>
              </div>
            </el-col>
            <el-col :span="12" style>
              <el-row>
                <el-col :span="15" style="text-align: end">
                  <div class="totals-text">Subtotal</div>
                </el-col>
                <el-col :span="9">
                  <div class="border-bottom totals-values">
                    <i class="mdi mdi-currency-usd"></i>
                    <span class="total-data">{{
                      this.formatNumber(subtotal)
                    }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="15" style="text-align: end">
                  <div class="totals-text">
                    <span class="discount1">
                      Descuento 1
                      <el-popover placement="right" width="200" trigger="click" @show="showCupon = true"
                        @hide="verifyDiscount">
                        <div>
                          <i class="mdi mdi-tag-minus"></i> Ingresa el numero
                          del cupón
                        </div>
                        <el-input placeholder="# Cupón" ref="cupon" size="medium" clearable
                          @keyup.enter.native="searchCupon" v-model="cupon_number">
                          <el-button slot="append" :icon="!loadingCupon
                            ? 'el-icon-search'
                            : 'el-icon-loading'
                            " @click="searchCupon"></el-button>
                        </el-input>
                        <el-tooltip content="Cupón" placement="top" slot="reference">
                          <i class="mdi mdi-tag-minus"></i>
                        </el-tooltip>
                      </el-popover>
                    </span>
                  </div>
                </el-col>
                <el-col :span="9">
                  <div class="border-bottom totals-values">
                    <i class="mdi mdi-currency-usd"></i>
                    <span class="total-data">{{
                      "-" + this.formatNumber(discount1)
                    }}</span>
                  </div>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="15" style="text-align: end">
                  <div class="totals-text">Subtotal</div>
                </el-col>
                <el-col :span="9">
                  <div class="border-bottom totals-values">
                    <i class="mdi mdi-currency-usd"></i>
                    <span class="total-data">{{
                      this.formatNumber(neto)
                    }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="15" style="text-align: end">
                  <div class="totals-text">IVA</div>
                </el-col>
                <el-col :span="9">
                  <div class="border-bottom totals-values">
                    <i class="mdi mdi-currency-usd"></i>
                    <span class="total-data">{{ this.formatNumber(iva) }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="adjustment">
                <el-col :span="15" style="text-align: end">
                  <div class="totals-text">IPC</div>
                </el-col>
                <el-col :span="9">
                  <div class="border-bottom totals-values">
                    <i class="mdi mdi-currency-usd"></i>
                    <span class="total-data">{{ this.formatNumber(ipc) }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="15" style="text-align: end">
                  <div class="totals-text">
                    <!-- <span class="discount2" @click="showDiscount = !showDiscount"> -->
                    Descuento2
                    <!-- <i class="mdi mdi-tooltip-edit"></i> -->
                    <!-- </span> -->
                  </div>
                </el-col>
                <el-col :span="9">
                  <div class="border-bottom totals-values">
                    <i class="mdi mdi-currency-usd"></i>
                    <!-- <span class="total-data" style="width: 80%;" v-if="showDiscount">
                      <el-input
                        type="number"
                        size="mini"
                        placeholder="0.00"
                        v-model="discount2"
                        @blur="showDiscount = false"
                        @keyup.enter.native="showDiscount = false"
                        :autofocus="true"
                      ></el-input>
                    </span>-->
                    <span class="total-data">{{
                      "-" + this.formatNumber(discount2)
                    }}</span>
                  </div>
                </el-col>
              </el-row>
              <el-row class="adjustment">
                <el-col :span="15" style="text-align: end">
                  <div class="totals-text">Ajuste al peso</div>
                </el-col>
                <el-col :span="9">
                  <div class="border-bottom totals-values">
                    <i class="mdi mdi-currency-usd"></i>
                    <span class="total-data">{{
                      this.formatNumber(adjustment)
                    }}</span>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
    <seller :moadlOpen="modalOpen" @close="modalOpen = false" @seller="setSeller($event, true)"></seller>
    <modal-product :modalProductOpen="modalProductOpen" :branch="user.branch" @close="modalProductOpen = false"
      @addProducts="addProducts"></modal-product>
    <!-- MODAL RETURN PAY -->
    <el-dialog :visible.sync="modalReturnPay" width="30%" :before-close="handleCloseReturnPay">
      <span slot="title">
        <i class="el-icon-money"></i> Aplica la devolución a un medio de pago
      </span>
      <span>Elige la forma de pago a la cual aplicar la devolución (-
        {{ this.return_pay }})</span>
      <el-table :data="payment_data" style="width: 100%; margin-bottom: 20px; margin-top: 20px"
        v-loading="loadingReturnPay">
        <el-table-column prop="descripcion" label="Forma de pago" width="180"></el-table-column>
        <el-table-column prop="value" label="Valor" width="110">
          <template slot-scope="scope">
            <span class="value_pay">$ {{ new Intl.NumberFormat().format(scope.row.value) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Acción">
          <template slot-scope="scope">
            <el-button size="mini" type="success" @click="
              handleAddReturnPay(scope.$index, scope.row);
            loadingReturnPay = true;
            ">
              <i class="el-icon-money"></i> Aplicar devolución
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
const axios = require('axios');
import * as qz from "qz-tray";
import PersonAndPay from "../../../views/apps/pos/PersonAndPay.vue";
import Seller from "../../../views/apps/pos/Seller.vue";
import Print from "../../../views/apps/pos/ModalPrint.vue";
import ModalProduct from "../../../views/apps/pos/ModalProduct.vue";
import { getUser, getDemo, getDataPos } from "@/utils/auth";
import { getProductByCode } from "@/api/product";
import { saveDocument, searchCupon, getDocumentById, getDailySale } from "@/api/pos";
import {
  roundToTheNearestAnything,
  formatNumber,
  formatInt,
  formatFloat,
} from "@/utils/number";
import { mapGetters } from "vuex";
export default {
  name: "Pos",
  data() {
    return {
      afterTax: true, // APLICAR DESCUENTO DESPUES DE IVA
      pathImagesUrl: process.env.VUE_APP_ROOT,
      urlImage: process.env.VUE_APP_ROOT + "static/images/uploads/",
      demo: getDemo(),
      taxValue: 19, // valor en porcentaje del iva
      msn1: "NO SE REALIZA REEMBOLSO - MERCANCIA EN PROMOCIÓN NO TIENE CAMBIO",
      msn2_title: "Política de cambio",
      msn2:
        "Se acepta únicamente el cambio de sus productos en un plazo maximo de 30 días " +
        "calendario desde la fecha de compra, siempre que estos no hayan sido usados " +
        "y tengan sus etiquetas originales. Mercancia en promoción no tiene cambio.",
      msn3_title: "Política de garanía",
      msn3:
        "Nuestros productos en vestuario tienen una garantía de treinta días, calzado sesenta días " +
        "y gorras quince días a partir de la fecha de compra. " +
        "<br>Recuerde presentar su factura al momento de realizar el cambio.",
      social_networks: "Siguenos en nuestras redes sociales: xplod.nyc",
      contact_print:
        "Contactanos Oficina principal 2692035 Celular 300 6619245",
      loading: false,
      loadingCupon: false,
      loadingReturnPay: false,
      modalReturnPay: false,
      innerWidth: 0,
      sidebarOpen: false,
      user: getUser(),
      dataPos: getDataPos(),
      code_product: null,
      discount_id: "",
      subtotal: 0,
      neto: 0,
      iva: 0,
      ipc: 0,
      adjustment: 0,
      discount1: 0,
      discount2: 0,
      total: 0,
      quantity_total: 0,
      total_pay: 0,
      showDiscount: false,
      showCupon: false,
      tableData: [],
      client_data: {},
      payment_data: [],
      modalOpen: false,
      modalPrintOpen: false,
      modalProductOpen: false,
      electronic: false,
      cupon_number: "",
      sellerSelected: "",
      seller: {
        id: "",
        name: "",
        nit: "",
        city: "",
      },
      resetFields: false,
      //printer
      id: "",
      company: {},
      document: {},
      detail: {},
      payments: [],
      detailTableText: "",
      paymentTableText: "",
      detailData: [
        {
          description: "",
          quantity: 0,
          price: 0,
        },
      ],
      data: {
        id: "",
        consecutive: "",
        branch: "",
        branch_nit: "",
        branch_address: "",
        client: "",
        client_name: "",
        client_address: "",
        client_nit: "",
        seller: "",
      },
      print_payment: 0,
      print_subtotal: 0,
      print_total: 0,
      print_discount: 0,
      print_iva: 0,
      print_ipc: 0,
      documentIdProduct: null,
      limitDescription: 30, // limite de caracteres para la descripcion en la factura
      // pausedBills
      pausedBills: 1,
      dailySale: 0,
    };
  },
  computed: {
    ...mapGetters([
      "id_document",
      "client",
      "payment",
      "printer_default",
      "printerReConnect",
      //for view
      "document_view",
      "detail_view",
      "btn_new",
      "getDailySale",
    ]),
    return_pay: function () {
      return this.total_pay - this.total;
    },
  },
  watch: {
    printerReConnect(val) {
      if (val) {
        this.startConnectionPrint();
      }
    },
    id_document(val) {
      if (val != "") {
        this.getDocumentById(val);
      }
    },
    showCupon(val) {
      if (val) {
        this.$nextTick(() => this.$refs.cupon.focus());
      }
    },
    showDiscount(newVal, oldVal) {
      this.calculateTotals();
    },
    payment: {
      deep: true,
      handler(val, oldVal) {
        this.payment_data = [];
        this.total_pay = 0;
        val.forEach((el) => {
          if (el.value !== "") {
            this.total_pay += parseFloat(el.value);
            this.payment_data.push(el);
          }
        });
      },
    },
    client: {
      deep: true,
      handler(val, oldVal) {
        this.client_data = val;
      },
    },
    // VIEW
    document_view: {
      deep: true,
      handler(val, oldVal) {
        this.setViewDocument(val);
      },
    },
    detail_view: {
      deep: true,
      handler(val, oldVal) {
        this.setViewDetail(val);
      },
    },
    btn_new(val) {
      if (!val) {
        this.resetDocument();
      }
    },
    getDailySale(val) {
      console.log('get daily sales:', val);
      if (val) {
        this.getDailySale_();
      }
    },
  },
  methods: {
    deleteDiscount(indx, props) {
      this.tableData[indx].discount = '';
      this.calculateTotals(props);
    },
    applyDiscount(indx, props) {
      const data = props.row;
      this.tableData[indx].discount = Math.round((data.price_calculate * data.quantity) * data.discount_percentage / 100);
      this.calculateTotals(props);
    },
    clearDiscount(data) {
      //
    },
    setViewDocument(data) {
      if (data !== "") {
        if (data.discount) {
          this.cupon_number = data.discount.codigo_barras;
        }
        this.discount1 = data.descuento_valor;
        this.seller = {
          id: data.seller.id,
          name: data.seller.nombre,
          nit: data.seller.documento,
          city: data.seller.ciudad,
        };
      }
    },
    setViewDetail(data) {
      let me = this;
      var d = {};
      this.tableData = [];
      if (data.length > 0) {
        data.forEach((el) => {
          d.files = el.product.files;
          d.id = el.producto_id;
          d.codigo_interno = el.product.codigo_interno;
          d.descripcion = el.product.descripcion;
          d.precio_venta = el.precio;
          d.cantidad = el.cantidad;
          d.descuento_valor = formatFloat(el.descuento);
          d.iva = (el.iva * el.precio) / 100;
          d.iva_percentage = el.iva;
          d.ipc = el.ipc;
          d.saldo = false;
          d.saldo_real = el.saldo_real;
          d.image = el.image;
          me.setProductToTable(d, true);
        });
        setTimeout(() => {
          me.calculateTotals();
        }, 500);
      }
    },
    searchCupon() {
      this.loadingCupon = true;
      var actual_date = this.actualDate();
      actual_date = actual_date.substr(0, 10);
      if (this.cupon_number == 0) {
        this.discount1 = 0;
        this.calculateTotals();
        this.loadingCupon = false;
      } else {
        if (this.cupon_number !== "") {
          searchCupon(this.cupon_number)
            .then(({ data }) => {
              this.loadingCupon = false;
              if (data) {
                if (data.cantidad > data.cant_restante) {
                  var actual = Date.parse(actual_date);
                  var fin = Date.parse(data.fecha_fin);
                  var inicio = Date.parse(data.fecha_inicio);
                  if (actual <= fin) {
                    if (actual >= inicio) {
                      this.messageDiscount(data);
                    } else {
                      this.$message({
                        message:
                          "El descuento sera valido apartir de el \n" +
                          data.fecha_inicio,
                        type: "warning",
                      });
                    }
                  } else {
                    this.$message({
                      message: "El descuento caduco el \n" + data.fecha_fin,
                      type: "warning",
                    });
                  }
                } else {
                  this.$message({
                    message:
                      "Lo sentimos, ha superado el tope que se puede aplicar el descuento.",
                    type: "warning",
                  });
                }
              } else {
                this.cupon_number = "";
                this.$message({
                  message: "Atención!, No hay datos con ese codigo.",
                  type: "warning",
                });
              }
            })
            .catch(function (error) {
              console.log(error);
              this.loadingCupon = false;
              this.$message.error("Oops", "Error al buscar.");
            });
        } else {
          this.loadingCupon = false;
          this.$message({
            message: "Atención!, Ingresa un número de cupon.",
          });
        }
      }
    },
    messageDiscount(data) {
      var discount_text = "(<strong>" + data.descuento + "%</strong>)";
      if (data.tipo_descuento == "0") {
        discount_text =
          "(<strong>$ " + formatNumber(data.descuento) + "</strong>)";
      }
      this.$confirm(
        "Adquiere un descuento de " +
        discount_text +
        "sobre el valor de la compra",
        "Felicitaciones!!",
        {
          confirmButtonText: "Si, Aplicar",
          cancelButtonText: "No, Cancelar",
          type: "success",
          center: true,
          dangerouslyUseHTMLString: true,
        }
      )
        .then(() => {
          this.discount_id = data.id;
          var valPay = this.subtotal;
          this.discount1 =
            data.tipo_descuento == "0"
              ? data.descuento
              : Math.round((parseFloat(valPay) * data.descuento) / 100);
          this.calculateTotals();
          this.$message({
            type: "success",
            message: "Descuento aplicado ",
          });
        })
        .catch(() => {
          this.cupon_number = "";
          this.$message({
            type: "info",
            message: "El descuento no fue aplicado",
          });
        });
    },
    verifyDiscount() {
      let me = this;
      setTimeout(() => {
        if (me.discount1 === 0 && me.cupon_number !== "") {
          me.searchCupon();
        }
      }, 300);
      this.showCupon = false;
    },
    addProducts(data) {
      let me = this;
      data.forEach((el) => me.search_product(el.product.codigo_interno));
    },
    //for print
    getDocumentById(id) {
      let me = this;
      getDocumentById(id)
        .then(({ data }) => {
          this.company = data.company;
          this.document = data.document;
          this.detail = data.detail;
          this.assignData(data.document);
          this.payments = data.document.payment_detail;
          this.print_payment = 0;
          me.paymentTableText = "";
          if (data.document.payment_detail.length > 0) {
            data.document.payment_detail.forEach(function (el) {
              me.paymentTableText +=
                '<tr><td style="padding-left:10px;">' +
                el.payment.descripcion +
                '</td><td style="text-align:right;padding-right:10px">' +
                "$ " +
                formatNumber(formatFloat(el.valor)) +
                "</td></tr>";
              me.print_payment += formatFloat(el.valor);
            });
          }

          this.detailData = [];
          if (data.detail.length > 0) {
            this.assignDataDetail(data.detail);
          }
          me.startPrint();
        })
        .catch(function (error) {
          console.log(error);
          this.$message.error("Oops", "Error.");
        });
    },
    startPrint() {
      let me = this;
      if (me.printer_default !== "") {
        if (!qz.websocket.isActive()) {
          me.startConnectionPrint();
        }
        setTimeout(() => {
          // VALIDO SI ES DOCUMENTO PARA BONO DE REGALO
          if (me.document.type.type_pivot_id == 15) {
            me.sendPrinterOtherDocument(me.printer_default);
          } else {
            me.sendPrinter(me.printer_default);
          }
        }, 300);
      } else {
        if (qz.websocket.isActive()) {
          qz.printers
            .getDefault()
            .then(function (data) {
              if (!data) {
                this.$swal.fire({
                  icon: "warning",
                  title: "Atención!",
                  text: "No hay una impresora predeterminada!",
                });
              } else {
                me.$store.commit("SET_PRINTER", data);
                me.startPrint();
              }
            })
            .catch(function (error) {
              console.log("error: ", error);
            });
        }
      }
    },
    assignData(data) {
      this.data = {
        id: data.id,
        consecutive: data.consecutivo,
        type: data.type,
        branch: data.branch.razon_social,
        branch_nit: data.branch.nit,
        branch_address: data.branch.direccion,
        client: data.person.nombre,
        client_nit: data.person.documento,
        client_address: data.person.direccion,
        client_phone: data.person.telefono,
        seller: data.seller.nombre,
        discount: data.descuento_valor,
      };
    },
    assignDataDetail(data) {
      let me = this;
      me.print_subtotal = 0;
      me.total_sale = 0;
      me.print_total = 0;
      me.print_discount = 0;
      me.print_iva = 0;
      me.print_ipc = 0;
      me.detailTableText = "";
      data.forEach(function (el, ind) {
        let subtot = 0
        if (me.afterTax) {
          subtot = formatNumber(formatFloat(el.precio))
        } else {
          subtot = formatNumber(formatFloat(el.precio - el.descuento))
        }
        me.detailTableText +=
          '<tr><td style="padding-left:10px;">' +
          el.product.descripcion.substr(0, me.limitDescription) +
          "<br>" +
          el.product.codigo_interno +
          '</td>'
          // +'<td style="">' +
          // el.product.category.descripcion +
          // '</td>'
          + '<td style="text-align:center;">' +
          el.cantidad +
          '</td><td style="">' +
          el.descuento +
          '</td><td style="text-align:right;padding-right:10px">' +
          "$ " + subtot +
          "</td></tr>";
        me.total_sale += formatFloat(el.precio) * el.cantidad;
        if (me.afterTax) {
          me.print_subtotal +=
            formatFloat(el.precio) * formatFloat(el.cantidad);
        } else {
          me.print_subtotal +=
            formatFloat(el.precio - el.descuento) * formatFloat(el.cantidad);
        }
        me.print_discount += formatFloat(el.descuento);
        me.print_iva = el.iva;
        me.print_ipc += el.ipc * el.cantidad;
        me.detailData.push({
          description: el.product.descripcion,
          quantity: el.cantidad,
          price: (me.afterTax) ? "$ " + formatNumber(formatFloat(el.precio)) : "$ " + formatNumber(formatFloat(el.precio - el.descuento)),
        });
      });
      this.print_discount =
        this.print_discount + formatFloat(this.document.discount1);
      if (
        this.document.descuentos_id != "" &&
        this.document.descuentos_id !== null
      ) {
        if (me.afterTax) {
          this.print_iva = Math.round(
            ((this.print_subtotal) * me.print_iva) / 100
          );
          this.print_total = roundToTheNearestAnything(
            this.print_subtotal +
            this.print_iva +
            me.print_ipc -
            this.print_discount
          );
        } else {
          this.print_iva = Math.round(
            ((this.print_subtotal - me.print_discount) * me.print_iva) / 100
          );
          this.print_total = roundToTheNearestAnything(
            this.print_subtotal +
            this.print_iva +
            me.print_ipc -
            this.print_discount
          );
        }
      } else {
        this.print_iva = Math.round((this.print_subtotal * me.print_iva) / 100);
        if (me.afterTax) {
          this.print_total = roundToTheNearestAnything(
            this.print_subtotal + this.print_iva + me.print_ipc - me.print_discount
          );
        } else {
          this.print_total = roundToTheNearestAnything(
            this.print_subtotal + this.print_iva + me.print_ipc
          );
        }
      }
    },
    sendPrinter(printer) {
      let me = this;
      var config = qz.configs.create(printer, { scaleContent: false }); // Create a default config for the found printer
      var document_text = "DOCUMENTO EQUIVALENTE";
      var noInvoice = "ESTE DOCUMENTO NO ES VALIDO COMO FACTURA DE VENTA";

      if (this.document.electronica == 1) {
        document_text = "DOCUMENTO EQUIVALENTE";
        noInvoice = "ESTE DOCUMENTO NO ES VALIDO COMO FACTURA ELECTRONICA";
      }
      var dataDian = "";
      var metadata = false;
      if (this.data.type.meta_data !== null) {
        metadata = JSON.parse(this.data.type.meta_data);
        // dataDian =
        //   '<div style="text-align:center;">Resolución DIAN ' +
        //   metadata.data_dian.resolution_dian +
        //   "</div>" +
        //   '<div style="text-align:center;">Autorizada el: ' +
        //   metadata.data_dian.autorization_dian +
        //   "</div>" +
        //   '<div style="text-align:center;">Vigencia: ' +
        //   metadata.data_dian.validity_dian +
        //   "</div>" +
        //   '<div style="text-align:center;">Prefijo POS del ' +
        //   metadata.data_dian.range_dian +
        //   "</div>";
      }
      var documentHead =
        '<div style="text-align:center;">' +
        '<img style="width:150px" src="' +
        this.pathImagesUrl +
        'static/images/logo.png" /> </br>' +
        this.company.razon_social +
        "</div>" +
        '<div style="text-align:center;">NIT: ' +
        this.company.nit +
        "</div>" +
        dataDian +
        '<div style="text-align:center;">Caja: ' +
        this.dataPos.computer.name +
        "</div>" +
        '<div style="margin: 10px 0 10px 0;"></div>' +
        '<div style="text-align:center;">' +
        this.data.branch +
        "</div>" +
        '<div style="text-align:center;">' +
        this.data.branch_address +
        "</div>" +
        '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
        // invoice
        '<div style="text-align:center;">' +
        document_text +
        " #" +
        this.data.type.prefijo +
        "" +
        this.data.consecutive.toString().padStart(6, "0") +
        "</div>" +
        '<div style="text-align:center;">RESPONSABLES DE IVA</div>' +
        '<div style="text-align:center;">' +
        this.actualDate(this.document.created_at) +
        "</div>";
      var documentFooter =
        '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
        // "<span style=\"font-size:'12px';font-weight: 900;\">" +
        // this.msn2_title +
        // "</span><br>" +
        // this.msn2 +
        // "</div>" +
        // '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
        // "<span style=\"font-size:'12px';font-weight: 900;\">" +
        // this.msn3_title +
        // "</span><br>" +
        // this.msn3 +
        // "</div>" +
        '<div style="font-size:10px;text-align:center;margin-top:20px;">' +
        this.contact_print +
        "</div>" +
        // '<div style="font-size:12px;text-align:center;margin-bottom:10px;">' +
        // this.social_networks +
        // "</div>" +
        // '<div style="font-size:10px;text-align:center;margin-bottom:10px;">https://www.xplodnyc.com</div>' +
        '<div style="font-size:12px;text-align:center;margin-bottom:10px;">' +
        noInvoice +
        "</div>" +
        '<div style="font-size:15px;text-align:center;margin-top:20px;font-weight: 900;">**** GRACIAS POR SU COMPRA ****</div>' +
        '<div style="text-align:center;margin-top:2px;margin-bottom:2px;font-size:9px">Desarrollado por aplicacionesexcel.com</div>';

      var background = "";
      if (this.demo) {
        background =
          "background-image: url('" +
          process.env.VUE_APP_ROOT +
          "static/images/demo.png');";
        documentHead =
          '<div style="font-size:30px;text-align:center;">DEMO</div>';
        documentFooter =
          '<div style="font-size:30px;text-align:center;">DEMO</div>';
      }

      let textDiscountAfeterTax = ''
      let textDiscount = ''
      if (this.afterTax) {
        textDiscountAfeterTax = '<div style="padding-left:10px;">Descuento <label style="float:right;padding-right:10px">$ -' +
          formatNumber(this.print_discount) +
          "</label></div>";
      } else {
        textDiscount = '<div style="padding-left:10px;">Descuento <label style="float:right;padding-right:10px">$ -' +
          formatNumber(this.print_discount) +
          "</label></div>";
      }


      var printData = [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data:
            "<html>" +
            '<body style="font-size:11px;' +
            background +
            '">' +
            documentHead +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // client
            '<div style="padding-left:10px;">Cliente: ' +
            this.data.client +
            "</div>" +
            '<div style="padding-left:10px;">Cedula O NIT: ' +
            this.data.client_nit +
            "</div>" +
            '<div style="padding-left:10px;">Teléfono: ' +
            this.data.client_phone +
            "</div>" +
            '<div style="padding-left:10px;">Vendedor: ' +
            this.data.seller +
            "</div>" +
            // table detail
            '<table style="width:100%;font-size:11px;">' +
            '<tr><td style="border-top: 1px dashed;border-bottom: 1px dashed;">Descripción</td>' +
            // '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Categoria</td>' +
            '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Cant.</td>' +
            '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Desto.</td>' +
            '<td style="text-align:right;border-top: 1px dashed;border-bottom: 1px dashed;padding-right:10px">Valor antes de IVA</td></tr>' +
            this.detailTableText +
            "</table>" +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            // totals
            '<div style="padding-left:10px;">Subtotal <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_subtotal) +
            "</label></div>" +
            textDiscount +
            '<div style="padding-left:10px;">IVA ' + this.taxValue + '% <label style="float:right;padding-right:10px">$ ' +
            formatNumber(Math.trunc(this.print_iva)) +
            "</label></div>" +
            '<div style="padding-left:10px;">Impo. al Consumo Bolsa <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_ipc) +
            "</label></div>" +
            textDiscountAfeterTax +
            '<div style="padding-left:10px;">Total <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_total) +
            "</label></div>" +
            '<div style="border-bottom: solid 1px;margin: 10px 0 10px 0;"></div>' +
            //payments
            '<table style="width:100%;font-size:11px;">' +
            this.paymentTableText +
            "</table>" +
            // cambio
            '<div style="padding-left:10px;">Cambio <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_total - this.print_payment) +
            "</label></div>" +
            // footer
            // '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
            // this.msn1 +
            // "</div>" +
            documentFooter +
            '<div style="margin: 20px 0 20px 0;border-style: dashed; border-width: 1px;"></div>' +
            "</div>" +
            "</body>" +
            "</html>",
        },
      ];
      qz.print(config, printData)
        .then(function () {
          me.$store.commit("SET_ID_DOCUMENT", "");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    sendPrinterOtherDocument(printer) {
      let me = this;
      var config = qz.configs.create(printer, { scaleContent: false }); // Create a default config for the found printer
      var metadata = false;
      if (this.data.type.meta_data !== null) {
        metadata = JSON.parse(this.data.type.meta_data);
      }
      var documentHead =
        '<div style="text-align:center;">' +
        this.company.razon_social +
        "</div>" +
        '<div style="text-align:center;">NIT: ' +
        this.company.nit +
        "</div>" +
        '<div style="margin: 10px 0 10px 0;"></div>' +
        '<div style="text-align:center;">' +
        this.data.branch +
        "</div>" +
        '<div style="text-align:center;">' +
        this.data.branch_address +
        "</div>" +
        '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
        // invoice
        '<div style="text-align:center;">' +
        this.data.type.descripcion +
        " #" +
        this.data.type.prefijo +
        "" +
        this.data.consecutive.toString().padStart(6, "0") +
        "</div>" +
        '<div style="text-align:center;">RESPONSABLES DE IVA</div>' +
        '<div style="text-align:center;">' +
        this.document.fecha +
        "</div>";
      var documentFooter =
        "Contactanos Oficina principal 032 379 0546 Celular 316 7433278" +
        "</div>" +
        '<div style="font-size:12px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
        "Siguenos en nuestras redes sociales: xplod.nyc" +
        "</div>" +
        '<div style="font-size:10px;text-align:center;margin-bottom:10px;">https://www.xplodnyc.com</div>' +
        '<div style="text-align:center;margin-top:2px;margin-bottom:2px;font-size:9px">Desarrollado por aplicacionesexcel.com</div>';
      var background = "";
      if (this.demo) {
        background =
          "background-image: url('" +
          process.env.VUE_APP_ROOT +
          "static/images/demo.png');";
        documentHead =
          '<div style="font-size:30px;text-align:center;">DEMO</div>';
        documentFooter =
          '<div style="font-size:30px;text-align:center;">DEMO</div>';
      }
      // console.log("sendPrint", this.data.consecutive, this.company, this.data);
      var printData = [
        {
          type: "pixel",
          format: "html",
          flavor: "plain",
          data:
            "<html>" +
            '<body style="font-size:11px;' +
            background +
            '">' +
            documentHead +
            '<div style="border-bottom: solid 1px;margin: 5px 0 5px 0;"></div>' +
            // client
            '<div style="padding-left:10px;">Cliente: ' +
            this.data.client +
            "</div>" +
            '<div style="padding-left:10px;">Cedula O NIT: ' +
            this.data.client_document +
            "</div>" +
            '<div style="padding-left:10px;">Teléfono: ' +
            this.data.client_phone +
            "</div>" +
            '<div style="padding-left:10px;">Vendedor: ' +
            this.data.seller +
            "</div>" +
            // table detail
            '<table style="width:100%;font-size:11px;">' +
            '<tr><td style="border-top: 1px dashed;border-bottom: 1px dashed;">Descripción</td>' +
            '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Categoria</td>' +
            '<td style="text-align:center;border-top: 1px dashed;border-bottom: 1px dashed;">Cant.</td>' +
            '<td style="text-align:right;border-top: 1px dashed;border-bottom: 1px dashed;padding-right:10px">Precio</td></tr>' +
            this.detailTableText +
            "</table>" +
            '<div style="margin: 10px 0 10px 0;border-style: dashed; border-width: 1px;"></div>' +
            // totals
            '<div style="padding-left:10px;">Subtotal <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_subtotal) +
            "</label></div>" +
            '<div style="padding-left:10px;">Descuento <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_discount) +
            "</label></div>" +
            '<div style="padding-left:10px;">IVA 0% <label style="float:right;padding-right:10px">$ ' +
            formatNumber(Math.trunc(this.print_iva)) +
            "</label></div>" +
            '<div style="padding-left:10px;">Impo. al Consumo Bolsa <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_ipc) +
            "</label></div>" +
            '<div style="padding-left:10px;">Total <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_total) +
            "</label></div>" +
            '<div style="border-bottom: solid 1px;margin: 10px 0 10px 0;"></div>' +
            //payments
            '<table style="width:100%;font-size:11px;">' +
            this.paymentTableText +
            "</table>" +
            // cambio
            '<div style="padding-left:10px;">Cambio <label style="float:right;padding-right:10px">$ ' +
            formatNumber(this.print_total - this.print_payment) +
            "</label></div>" +
            '<div style="padding-left:10px;">OBSERVACIÓN: ' +
            (this.document.observacion == "null" ||
              this.document.observacion === null
              ? ""
              : this.document.observacion) +
            "</div>" +
            '<div style="font-size:10px;text-align:center;margin-top:20px;margin-bottom:10px;">' +
            documentFooter +
            '<div style="margin: 20px 0 20px 0;border-style: dashed; border-width: 1px;"></div>' +
            "</div>" +
            "</body>" +
            "</html>",
        },
      ];
      qz.print(config, printData)
        .then(function () {
          me.$store.commit("SET_ID_DOCUMENT", "");
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    // finish print
    ValidateDocument(electronic) {
      this.electronic = electronic;
      let me = this;
      if (this.tableData.length > 0) {
        if (this.seller.id === "") {
          this.$message({
            message: "Atención!, Debes seleccionar un vendedor.",
            type: "warning",
          });
          setTimeout(() => {
            me.modalOpen = true;
          }, 1000);
          this.$store.commit("SET_BTN_SAVED", false);
        } else {
          if (this.return_pay > 0) {
            if (this.payment_data.length > 1) {
              setTimeout(() => {
                me.loadingReturnPay = false;
              }, 500);
              this.$store.commit("SET_BTN_SAVED", false);
              this.modalReturnPay = true;
            } else {
              // console.log("return", this.return_pay);
              this.payment_data[0].return_pay = this.return_pay;
              this.saveDocument();
            }
          } else {
            // console.log("return2", this.return_pay);
            this.payment_data[0].return_pay = this.return_pay;
            this.saveDocument();
          }
        }
      } else {
        this.$store.commit("SET_BTN_SAVED", false);
        this.$message({
          message: "Atención!, Debes ingresar productos en la factura.",
          type: "warning",
        });
        this.$nextTick(() => this.$refs.product.focus());
      }
    },
    saveDocument() {
      let me = this;
      var data = {
        document: {
          type: 1, // 1 es type_pivot_id factura de venta
          electronic: this.electronic,
          user: this.user,
          subtotal: this.subtotal,
          iva: this.iva,
          adjustment: this.adjustment,
          discount_id: this.discount_id,
          discount1: this.discount1,
          discount2: this.discount2,
          total: this.total,
          client_data: this.client_data,
          seller_data: this.seller,
          payment_data: this.payment_data,
          document_id_product: this.documentIdProduct,
        },
        detail: this.tableData,
        data_pos: this.dataPos.computer,
      };

      saveDocument(data)
        .then(({ data }) => {
          this.$store.commit("SET_BTN_SAVED", false);
          if ((data.code = 200)) {
            this.$message({
              type: "success",
              message: "Registro exitoso!",
            });
            this.id = data.document.id;
            this.resetDocument();
            this.getDailySale_()
            setTimeout(() => {
              me.validatePrintDocument(data.document.id);
            }, 500);
          } else {
            this.$message.error("Oops, Error.");
            console.log(data.data.error);
          }
        })
        .catch((error) => {
          this.$store.commit("SET_BTN_SAVED", false);
          let err = error;
          if (error.response) {
            err = error.response.data.message;
          }
          this.$message.error("Oops, Error al registrar. " + err);
          console.log(error);
        });
    },
    getData() {
      this.tableData = [];
    },
    calculateTotals(props) {
      let me = this;
      if (props) {
        var total =
          formatInt(props.row.quantity) * formatFloat(props.row.price);
        var iva = (total * props.row.iva_percentage) / 100;

        me.tableData[props.$index].total = formatNumber(total);
        me.tableData[props.$index].iva = iva;
      }
      // CALCULAR TOTALES
      me.subtotal = 0;
      me.iva = 0;
      me.ipc = 0;
      me.quantity_total = 0;
      me.discount2 = 0;
      if (this.cupon_number === "") {
        me.discount1 = 0;
      }
      var percent_iva =
        me.tableData.length > 0 ? me.tableData[0].iva_percentage : 0;

      me.tableData.forEach(function (elem, index, array) {
        me.tableData[index].discount = formatFloat(
          me.tableData[index].discount == 0 ||
            me.tableData[index].discount == ""
            ? 0
            : me.tableData[index].discount
        );

        if (me.afterTax) {
          // SE APLICA EL DESCUENTO DESPUES DE IVA
          me.tableData[index].subtotal =
            formatFloat(
              me.tableData[index].price_calculate
            ) * me.tableData[index].quantity;
        } else {
          me.tableData[index].subtotal =
            formatFloat(
              me.tableData[index].price_calculate - me.tableData[index].discount
            ) * me.tableData[index].quantity;
        }

        me.tableData[index].iva =
          (me.tableData[index].subtotal * me.tableData[index].iva_percentage) /
          100;

        if (me.afterTax) {
          // SE APLICA EL DESCUENTO DESPUES DE IVA  
          me.tableData[index].total = formatNumber(me.tableData[index].subtotal);
        } else {
          me.tableData[index].total = formatNumber(me.tableData[index].subtotal);
        }

        me.subtotal += me.tableData[index].subtotal;
        me.iva += me.tableData[index].iva;
        me.ipc += me.tableData[index].ipc * me.tableData[index].quantity;
        me.discount2 += me.tableData[index].discount;
        me.quantity_total += me.tableData[index].quantity;
      });

      if (me.afterTax) {
        // SE APLICA EL DESCUENTO DESPUES DE IVA
        me.neto = me.subtotal;
        me.iva = Math.round((me.neto * percent_iva) / 100);
        let tot = (me.neto + me.iva + me.ipc) - formatFloat(me.discount2)
        me.total = roundToTheNearestAnything(tot);
        console.log('entro', me.neto, me.iva, tot);
      } else {
        me.neto = me.subtotal - formatFloat(me.discount2);
        me.iva = Math.round((me.neto * percent_iva) / 100);
        me.total = roundToTheNearestAnything(me.neto + me.iva + me.ipc);
      }
    },
    search_product(code_search) {
      let me = this;
      me.loading = true;
      var code = me.code_product;
      if (typeof code_search != "object" && code_search != "") {
        code = code_search;
      } else {
        if (me.code_product == "") {
          code = false;
        }
      }
      getProductByCode(code, me.user.sucursal_id)
        .then(({ data }) => {
          if (data.code === 200) {
            // SI HAY REGISTROS EN EL DETALLE DEL DOCUMENTO
            if (me.tableData.length > 0) {
              // VALIDO SI EL document_id = documentIdProduct
              if (me.documentIdProduct == data.data.document_id) {
                me.addProductToDetailDocument(data);
              } else {
                this.$swal
                  .fire({
                    title:
                      "<strong>El producto no puede registrarse " +
                      "con el(los) producto(s) que están actualmente agregados!</strong>",
                    icon: "warning",
                    html: "Deseas borrar el detalle actual y agregar el nuevo producto?",
                    showCloseButton: true,
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    focusConfirm: false,
                    confirmButtonText:
                      '<i class="mdi mdi-clipboard-check-outline"></i> Borrar y agregar producto!',
                    cancelButtonText:
                      '<i class="mdi mdi-close-circle-outline"></i> Cancelar',
                  })
                  .then((result) => {
                    if (result.isConfirmed) {
                      me.resetDocument();
                      // AGREGO EL PRODUCTO
                      setTimeout(() => {
                        me.documentIdProduct = data.data.document_id;
                        me.addProductToDetailDocument(data);
                      }, 500);
                    } else {
                      me.code_product = "";
                      me.loading = false;
                    }
                  });
              }
            } else {
              // GUARDO EL document_id EN documentIdProduct Y AGREGO EL PRODUCTO
              me.documentIdProduct = data.data.document_id;
              me.addProductToDetailDocument(data);
            }
          } else {
            me.loading = false;
            me.$message({
              message: "Atención!, " + data.error,
              type: "error",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          me.$message.error("Oops", "Error registrar cliente.");
          me.loading = false;
        });
    },
    addProductToDetailDocument(data) {
      let me = this;
      data.data.saldo_real = data.saldo[0].saldo;
      // VALIDO QUE EL DETALLE TENGA REGISTROS
      if (me.tableData.length > 0) {
        var exist = false;
        me.tableData.find(function (val, i) {
          // VALIDO SI YA ESTA EL PRODUCTO EN EL DETALLE
          if (data.data.id === val.id) {
            exist = true;
            // VALIDO SI EL SALDO ES MAYOR A LA CANTIDAD O QUE SE FACTURA SIN STOCK
            if (data.data.saldo > val.quantity || data.data.saldo === false) {
              // SUMO UNO A LA CANTIDAD
              val.quantity += 1;
            } else {
              // RESTAURO AL SALDO REAL ACTUAL DEL PRODUCTO
              val.quantity = data.data.saldo;
              me.$message({
                message: "Atención!, El saldo del producto es insuficiente.",
                type: "warning",
              });
            }
          }
        });
        if (!exist) {
          me.setProductToTable(data.data);
        }
      } else {
        if (!data.data.saldo || data.data.saldo > 0) {
          // SI EL DETALLE NO TIENE REGISTROS, AGREGO EL PRODUCTO
          me.setProductToTable(data.data);
          me.messageMinimumStock(data);
        } else {
          me.$message({
            message: "Atención!, El saldo del producto es insuficiente.",
            type: "warning",
          });
        }
      }
      // enviar mensaje cuando el producto llega al stock minimo
      me.calculateTotals();
      me.code_product = "";
      me.loading = false;
    },
    messageMinimumStock(data) {
      if (
        parseFloat(data.saldo[0].saldo) <= parseFloat(data.data.stock_minimo)
      ) {
        this.$swal.fire({
          icon: "warning",
          title: "Atención!",
          html:
            "El stock del producto <strong>'" +
            data.data.descripcion +
            "'</strong> con código <strong>'" +
            data.data.codigo_interno +
            "'</strong> esta en: <strong>" +
            data.saldo[0].saldo +
            "</strong> unidades",
        });
      }
    },
    setProductToTable(data, view) {
      var image = data.files[0] ? data.files[0].name_file : "no-image.png";
      this.tableData.push({
        id: data.id,
        code: data.codigo_interno,
        url: "/static/images/products/" + image,
        image: data.image,
        srcList: ["/static/images/products/" + image],
        name: data.codigo_interno + " " + data.descripcion,
        quantity: view ? data.cantidad : 1,
        price: formatNumber(formatFloat(data.precio_venta)),
        price_calculate: formatFloat(data.precio_venta),
        total: formatNumber(formatFloat(data.precio_venta)),
        discount: data.descuento_valor,
        iva: formatNumber(formatFloat(data.iva)),
        iva_percentage: formatFloat(data.iva_percentage),
        ipc: data.precio_venta == 0 ? 0 : data.ipc,
        saldo: formatInt(data.saldo_real),
        balance: data.saldo !== false ? data.saldo : 999,
        document_id: data.document_id,
        discount_percentage: data.discount_percentage,
      });
    },
    deleteRow(index, row) {
      this.$confirm("Quieres eliminar este registro?", "Atención!", {
        confirmButtonText: "Si",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "Registro Eliminado",
          });
          this.tableData.splice(index, 1);
          this.calculateTotals();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Operación Cancelada",
          });
        });
    },
    resizeOpenNav() {
      this.innerWidth = window.innerWidth;
    },
    formatNumber(number, round, aprox) {
      //PASAR INTEGER A FORMATO NUMERO
      var num = formatNumber(number);
      if (typeof round != "undefined") {
        // REDONDEO Y QUITO LOS DECIMALES
        num = formatNumber(Math.trunc(number));
      }
      if (typeof aprox != "undefined") {
        // APROXIMO AL ENTERO MAS CERCANO
        num = roundToTheNearestAnything(number);
      }
      return num;
    },
    setSellerWithName(name) {
      console.log(name);
      var seller = this.dataPos.sellers.find(
        (element) => element.seller.nombre === name
      );
      this.setSeller(seller);
    },
    setSeller(data, table) {
      if (typeof table !== "undefined") {
        this.sellerSelected = "";
      }
      this.seller = {
        id: data.seller.id,
        nit: data.seller.documento,
        name: data.seller.nombre,
        city: data.seller.ciudad,
      };
      this.modalOpen = false;
    },
    resetDocument() {
      let me = this;
      this.cupon_number = "";
      this.code_product = null;
      this.subtotal = 0;
      this.neto = 0;
      this.iva = 0;
      this.adjustment = 0;
      this.discount1 = 0;
      this.discount2 = 0;
      this.total = 0;
      this.showDiscount = false;
      this.tableData = [];
      this.client_data = {};
      this.payment_data = {};
      this.resetFields = true;
      this.payments = [];
      setTimeout(() => {
        me.resetFields = false;
      }, 1000);
      this.$store.commit("SET_DOCUMENT_VIEW", "");
      this.$store.commit("SET_DETAIL_VIEW", "");
    },
    actualDate(date) {
      Number.prototype.padLeft = function (base, chr) {
        var len = String(base || 10).length - String(this).length + 1;
        return len > 0 ? new Array(len).join(chr || "0") + this : this;
      };
      var d = typeof date != "undefined" ? new Date(date) : new Date(),
        dformat =
          [
            d.getFullYear(),
            (d.getMonth() + 1).padLeft(),
            d.getDate().padLeft(),
          ].join("-") +
          " " +
          [
            d.getHours().padLeft(),
            d.getMinutes().padLeft(),
            d.getSeconds().padLeft(),
          ].join(":");
      return dformat;
    },
    startConnectionPrint() {
      let me = this;
      setTimeout(() => {
        // Authentication setup ///
        var privateKey =
          "-----BEGIN PRIVATE KEY-----\n" +
          "MIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCn13RTAjw4IfTw\n" +
          "d46mk3Lw7YLFJbIGdZvV4ieg51+fqqyUk81+q0icBIAnsrg3j2J+oBlwd1HLgaeI\n" +
          "C+8tEVn3ed9tzvxhJ5iGmd9DUorPIP2RsX+62uKi7OV4fXYBkqSXKWdaoI0nLEwf\n" +
          "FkCL7b8UN3LUazECIsK4bXeYIFMgAERYr6lvtP8HTJ3t6d/W0BWNQgXpUMuGJhvo\n" +
          "5yiJhsVZp8YjF/xyBcQRZjx58Ze4n426dM4lT5cH7EDJk5iogL/9D5YmPZ8dFMVZ\n" +
          "yaAfVVvT1x3vC7GqboKbYISNZLyVM3/+F3ikkcGe/NLbKUwdnFc+rKOdGp/Dup8T\n" +
          "HWVpm9MfAgMBAAECggEAGV2Z8mudjzwuiOMAt5MEJX43q7TZNVmz3fyqUYp2LKq+\n" +
          "H0xBG7omJJIXjihX5+mvEIUAozsdLPzgxVJMaMq9BoYwKqfEA6jYx8rcb4+7c7dA\n" +
          "KzMgoywuYUIdIfsQCQfRARlcSdmGy5tJu88AnUUmghcpLwouI2z+caH1l5OXDHhP\n" +
          "LbN+opDi0EhCd+PoNInkpj9s12/tl8rOOlHJSGUSgIAZGyafS5llBm3JoO4dZUmQ\n" +
          "0Lq1tKI25ai7B7EtZ+3Dyvya6N+5ACXucwlsufROc4kLrGbAffEPkreHydD78iSf\n" +
          "d1VMUuDABnUawPOLnd3yKBwAfvxN6jhjlYThw7rNRQKBgQDVX17U7H+IZsbvbknT\n" +
          "XsJQJlE+fdUYI6EImBDs9MG34akpTi+MdgdKJOKTJIVQIm5pxOD1nxzrY1vlNRML\n" +
          "hA8o0ZACcsION/476puRfG2LhxVak+gN+P0NlWXY4R1QsLSsc/KaL1MoBO2lom7+\n" +
          "C9l+KIG544ZzfK/hirVhPKQQZQKBgQDJX3iMS37VLu+2OQ26L9xstH33C9R73DJJ\n" +
          "mpPXra1KA1hgPYETy7769LMdYV7vKsyh4++4VgWAmSLkOK0H/ViQpmcW+kyyLQ9w\n" +
          "euD87zRcjIIY0ehI8JyLHX9Dxcuy4/oJDn+0IRXXkHDfSrw6kfkFuP+khTSRbbAj\n" +
          "q0L7vyfjMwKBgCwC5f6pYYzzFimg6B1vOElr/eoT7nZyMu8g3qzht2uEYm4gsHIa\n" +
          "jlpezVVofQ8QaxHqCDThwwdSkUvv7KO9c5sJztiSbzCboBcfx0L1FWtoS6FJTN5E\n" +
          "831vyXg4xuEz8bB4ohvnkxF4IsS9goNoUk5RcKC1K7B6kZTGFa+e4IB5AoGAccGK\n" +
          "+cYUKh3PsKLeTFWJMadsmUiHikjD56x45Bfqkqr9G7Di3+nzQxVEsc41sOn+m12G\n" +
          "KB4AEbtwaHhT1vWZOG2oQuS2utiVpjbkLj561+VQAPFjRQEXjcbmA8US40XCE6er\n" +
          "IHioPBQOjzVKy9N2V3NAhxHwtk6+eAfhWRGNWN0CgYA6XAiF1Al+kYkosSKsjpxo\n" +
          "BdKzT+5vCw37Iq3F+DYxzRb8MKx9XpeGoEWbG62Xez7eZ2B6UaARGDu43Ioa/6vk\n" +
          "bh9647T6nehoGlagzdu92kO5FCFsoIB2pBBZAS5k7S8jtSS7To2W3FDY/o3wwIRg\n" +
          "spuV+871lajMrXi3/faa/w==\n" +
          "-----END PRIVATE KEY-----";

        qz.security.setSignatureAlgorithm("SHA512"); // Since 2.1
        qz.security.setSignaturePromise(function (toSign) {
          return function (resolve, reject) {
            try {
              var pk = KEYUTIL.getKey(privateKey);
              var sig = new KJUR.crypto.Signature({ alg: "SHA512withRSA" }); // Use "SHA1withRSA" for QZ Tray 2.0 and older
              sig.init(pk);
              sig.updateString(toSign);
              var hex = sig.sign();
              // console.log("DEBUG: \n\n" + stob64(hextorstr(hex)));
              resolve(stob64(hextorstr(hex)));
            } catch (err) {
              console.error(err);
              reject(err);
            }
          };
        });
        qz.security.setCertificatePromise(function (resolve, reject) {
          resolve(axios.get(process.env.VUE_APP_ROOT_API + '/certs/getQzCertificate').then(response => response.data))
        });
        if (!qz.websocket.isActive()) {
          qz.websocket.connect().then(function () {
            console.log("Connected QZ-tray! ", qz.websocket.isActive());
            me.$store.commit("SET_PRINTER_CONNECT", true);
            me.$store.commit("SET_PRINTER_RECONNECT", false);
            qz.printers
              .getDefault()
              .then(function (data) {
                me.$store.commit("SET_PRINTER", data);
              })
              .catch(function (error) {
                console.log("error: ", error);
              });
          });
        } else {
          me.$message({
            message: "La conección con la impresora, ya ha sido establecida.",
            type: "success",
          });
          me.$store.commit("SET_PRINTER_CONNECT", true);
          me.$store.commit("SET_PRINTER_RECONNECT", false);
        }
      }, 2000);
    },
    handleAddReturnPay(index, row) {
      let me = this;
      console.log("hand", row);
      if (parseFloat(row.value) - parseFloat(this.return_pay) < 0) {
        this.$message({
          type: "warning",
          message: "El valor es menor a la devolución, elige otra opción",
        });
        setTimeout(() => {
          me.loadingReturnPay = false;
        }, 500);
      } else {
        this.payment_data[index].return_pay = this.return_pay;
        this.modalReturnPay = false;
        this.loadingReturnPay = false;
        this.$store.commit("SET_BTN_SAVED", true);
        this.saveDocument();
      }
    },
    handleCloseReturnPay(data) {
      let me = this;
      this.modalReturnPay = false;
      setTimeout(() => {
        me.loadingReturnPay = false;
      }, 500);
    },
    validatePrintDocument(documentId) {
      let me = this;
      this.$confirm('Desea imprimir el comprobante?', 'Información', {
        confirmButtonText: 'Si, imprimir',
        cancelButtonText: 'Cancelar',
        type: 'warning'
      }).then(() => {
        me.getDocumentById(documentId);
        this.$message({
          type: 'success',
          message: 'Imprimiendo comprobante'
        });
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: 'Acción cancelada'
        // });
      });
    },
    getDailySale_() {
      let me = this
      getDailySale().then(({ data }) => {
        me.dailySale = me.formatNumber(data.data.sales)
        this.$store.commit("SET_DAILY_SALE", false);
        console.log('vd: ', data);
      }).catch(function (error) {
        console.log(error);
        me.$message.error("Oops", "Error al traer la venta diaria.");
      });
    }
  },
  mounted() {
    let me = this;
    me.loading = true;
    this.resizeOpenNav();
    window.addEventListener("resize", this.resizeOpenNav);
    setTimeout(() => {
      me.getData();
      me.calculateTotals();
      me.loading = false;
    }, 1000);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeOpenNav);
  },
  created() {
    let me = this;
    me.startConnectionPrint();
    me.getDailySale_();
    document.addEventListener("keydown", (e) => {
      if (e.key == "F4") {
        this.modalOpen = true;
      }
    });
  },
  components: {
    PersonAndPay,
    Seller,
    Print,
    ModalProduct,
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/_variables";
@import "../../../assets/scss/app/pos";

.dailySaleValue {
  color: #5f8fdf;
}

.btn-plastic-bag {
  width: 100%;
}

.discount1 {
  cursor: pointer;
}

.btn-seller {
  margin-bottom: 10px;
}

.seller {
  font-size: 20px;
  font-weight: bold;
}

.txt-iva {
  width: 100%;
  padding: 5px;
}

.search-product {
  width: 100%;
}

.el-input-number {
  width: 100%;
}

.remove-col {
  visibility: hidden !important;
}

@media (min-width: 1400px) {
  .btn-delete-circle {
    width: 90% !important;
  }
}

@media (max-width: 600px) {
  .search-product {
    width: 100%;
  }
}
</style>
<style lang="css">
.el-table .cell {
  padding-right: 0;
}

.adjustment {
  display: none;
}

.el-image__error {
  font-size: 9px !important;
}

.el-image-viewer__close {
  color: cornsilk;
}
</style>
