import request from '@/utils/request'

export function getSales(page_size, currentPage, product, dateRange, payments) {
  return request({
    url: 'sales/getSales/' + page_size + '?page=' + currentPage,
    method: 'get',
    params: {
      'dateRange':dateRange, 
      'product':product,
      'payments':payments,
    }
  })
}

export function dowloadSales(data) {
  return request({
    url: 'sales/dowloadSales',
    method: 'post',
    data,
    responseType: 'blob'
  });
}